import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subject, filter, takeUntil } from 'rxjs';
import { clearSessionAction } from 'src/app/stores/global/app.actions';
import { selectBusinessAccount, selectHasSubscriptionPlanOrder } from 'src/app/stores/global/app.selectors';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  ngUnsubscribeState = new Subject<void>();
  isAuthenticated$ = this.oidcSecurityService.isAuthenticated$;
  hasBusinessAccount: boolean = false;
  accountPortalUrl: string = environment.accountPortalUrl;
  hasNoActiveSubscriptionPlanOrder: boolean;

  private _isXLarge: boolean;
  private _isLarge: boolean
  private _isMedium: boolean;
  private _isSmall: boolean;
  private _isXSmall: boolean;

  constructor(
    private readonly router: Router,
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly store: Store<any>,
    private readonly responsive: BreakpointObserver,
  ) {
    // Do nothing
  }

  ngOnInit(): void {
    this.oidcSecurityService.isAuthenticated$.pipe(filter(isAuthenticated => Boolean(isAuthenticated))).subscribe(
      _ => {
        this.store.select(selectBusinessAccount).pipe(filter(result => Boolean(result))).subscribe((_) => {
          this.hasBusinessAccount = true;
        });
      });

    this.store.select(selectHasSubscriptionPlanOrder).pipe(takeUntil(this.ngUnsubscribeState)).subscribe((hasSubscriptionPlanOrder) => {
      if (hasSubscriptionPlanOrder) {
        this.hasNoActiveSubscriptionPlanOrder = false;
      }
      else {
        this.hasNoActiveSubscriptionPlanOrder = true;
      }
    });

    this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge]).subscribe(
      result => {
        const breakpoints = result.breakpoints;

        this._isXLarge = false;
        this._isLarge = false;
        this._isMedium = false;
        this._isSmall = false;
        this._isXSmall = false;

        if (breakpoints[Breakpoints.Medium]) {
          this._isMedium = true;
        }
        else if (breakpoints[Breakpoints.Small]) {
          this._isSmall = true;
        }
        else if (breakpoints[Breakpoints.XSmall]) {
          this._isXSmall = true;
        }
        else if (breakpoints[Breakpoints.Large]) {
          this._isLarge = true;
        }
        else {
          this._isXLarge = true;
        }
      }
    );
  }

  login() {
    this.oidcSecurityService.authorize();
  }

  logout() {
    this.store.dispatch(clearSessionAction());
    this.oidcSecurityService.logoff().subscribe(_ => {
      this.oidcSecurityService.logoffLocal();
      this.router.navigate(['/home']);
    });
  }

  public goToCustomerPortal(): void {
    this.router.navigate(['/billing/portal']);
  }

  isXLarge(): boolean {
    return this._isXLarge;
  }

  isLarge(): boolean {
    return this._isLarge;
  }

  isMedium(): boolean {
    return this._isMedium;
  }
  isSmall(): boolean {
    return this._isSmall;
  }
  isXSmall(): boolean {
    return this._isXSmall;
  }
}
