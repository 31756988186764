import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CreateStoreLocationModel } from 'src/app/models/create-store-location-model';
import {
  CategoryBagelShop,
  CategoryBakery,
  CategoryBar,
  CategoryBarberShop,
  CategoryBodyPieringShop,
  CategoryCafeCoffeeAndTeaHouse,
  CategoryCafeteria,
  CategoryDryCleaner,
  CategoryFarmersMarket,
  CategoryFastFoodRestaurant,
  CategoryFitnessCenter,
  CategoryFoodStand,
  CategoryFoodTruck,
  CategoryFruitAndVegetableStore,
  CategoryGroceryStore,
  CategoryHairSalon,
  CategoryHealthFoodStore,
  CategoryHotel,
  CategoryIceCreamParlor,
  CategoryJuiceBar,
  CategoryMassageClinic,
  CategoryNailSalon,
  CategoryPetService,
  CategoryRestaurant,
  CategoryRetailStore,
  CategorySkinCareClinic,
  CategorySpa,
  CategoryTattooParlor,
  CategoryYogaStudio,
  StoreLocationCategory,
} from 'src/app/models/store-location-category-model';
import { StoreLocationOption } from 'src/app/models/store-location-option-model';
import { CreateStoreLocationComponentStore } from 'src/app/stores/components/store-location-create/create-store-location.component-store';
import { MaximumActiveStoreLocationsExceededException } from '../../exceptions/maximum-active-store-locations-exceeded-exception';
import { ValidationException } from '../../exceptions/validation-exception';
import { StoreLocationType, TypeOther, TypeVegan, TypeVegetarian } from 'src/app/models/store-location-type-model';
import { SpinnerService } from 'src/app/services/spinner.service';
import { MatDialogRef } from '@angular/material/dialog';
import { SpinnerDialogComponent } from '../dialogs/spinner-dialog/spinner-dialog.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

interface StoreLocationTypeViewModel {
  value: StoreLocationType,
  viewValue: string,
}

interface StoreLocationCategoryViewModel {
  value: StoreLocationCategory,
  viewValue: string,
}

@Component({
  selector: 'app-store-location-create',
  templateUrl: './store-location-create.component.html',
  styleUrls: ['./store-location-create.component.scss']
})
export class StoreLocationCreateComponent implements OnInit {
  @Input()
  public storeLocationDetailsFormGroup: UntypedFormGroup;

  @Output()
  public onCancelPressed = new EventEmitter();

  @Output()
  public onSavePressed = new EventEmitter();

  public readonly minNameLength: number = 1;
  public readonly maxNameLength: number = 32;
  public readonly minDescriptionLength: number = 3;
  public readonly maxDescriptionLength: number = 255;
  public readonly minAddressLineLength: number = 3;
  public readonly maxAddressLineLength: number = 128;
  public readonly minPostalCodeLength: number = 1;
  public readonly maxPostalCodeLength: number = 32;
  public readonly minCityLength: number = 3;
  public readonly maxCityLength: number = 128;
  public readonly maxCountryLength: number = 32;
  public readonly minPhoneNumberLength: number = 8;
  public readonly maxPhoneNumberLength: number = 32;

  public readonly storeLocationTypes: StoreLocationTypeViewModel[] = [
    { value: StoreLocationType.VEGAN, viewValue: TypeVegan },
    { value: StoreLocationType.VEGETARIAN, viewValue: TypeVegetarian },
    { value: StoreLocationType.OTHER, viewValue: TypeOther },
  ];

  public readonly storeLocationCategories: StoreLocationCategoryViewModel[] = [
    { value: StoreLocationCategory.BAGEL_SHOP, viewValue: CategoryBagelShop },
    { value: StoreLocationCategory.BAKERY, viewValue: CategoryBakery },
    { value: StoreLocationCategory.BAR, viewValue: CategoryBar },
    { value: StoreLocationCategory.BARBER_SHOP, viewValue: CategoryBarberShop },
    { value: StoreLocationCategory.BODY_PIERCING_SHOP, viewValue: CategoryBodyPieringShop },
    { value: StoreLocationCategory.CAFE_COFFEE_AND_TEA_HOUSE, viewValue: CategoryCafeCoffeeAndTeaHouse },
    { value: StoreLocationCategory.CAFETERIA, viewValue: CategoryCafeteria },
    { value: StoreLocationCategory.DRY_CLEANER, viewValue: CategoryDryCleaner },
    { value: StoreLocationCategory.JUICE_BAR, viewValue: CategoryJuiceBar },
    { value: StoreLocationCategory.FARMERS_MARKET, viewValue: CategoryFarmersMarket },
    { value: StoreLocationCategory.FAST_FOOD_RESTAURANT, viewValue: CategoryFastFoodRestaurant },
    { value: StoreLocationCategory.FITNESS_CENTER, viewValue: CategoryFitnessCenter },
    { value: StoreLocationCategory.FOOD_STAND, viewValue: CategoryFoodStand },
    { value: StoreLocationCategory.FOOD_TRUCK, viewValue: CategoryFoodTruck },
    { value: StoreLocationCategory.FRUIT_AND_VEGETABLE_STORE, viewValue: CategoryFruitAndVegetableStore },
    { value: StoreLocationCategory.GROCERY_STORE, viewValue: CategoryGroceryStore },
    { value: StoreLocationCategory.HAIR_SALON, viewValue: CategoryHairSalon },
    { value: StoreLocationCategory.HEALTH_FOOD_STORE, viewValue: CategoryHealthFoodStore },
    { value: StoreLocationCategory.HOTEL, viewValue: CategoryHotel },
    { value: StoreLocationCategory.ICE_CREAM_PARLOR, viewValue: CategoryIceCreamParlor },
    { value: StoreLocationCategory.MASSAGE_CLINIC, viewValue: CategoryMassageClinic },
    { value: StoreLocationCategory.NAIL_SALON, viewValue: CategoryNailSalon },
    { value: StoreLocationCategory.PET_SERVICE, viewValue: CategoryPetService },
    { value: StoreLocationCategory.RESTAURANT, viewValue: CategoryRestaurant },
    { value: StoreLocationCategory.RETAIL_STORE, viewValue: CategoryRetailStore },
    { value: StoreLocationCategory.SKIN_CARE_CLINIC, viewValue: CategorySkinCareClinic },
    { value: StoreLocationCategory.SPA, viewValue: CategorySpa },
    { value: StoreLocationCategory.TATTOO_PARLOR, viewValue: CategoryTattooParlor },
    { value: StoreLocationCategory.YOGA_STUDIO, viewValue: CategoryYogaStudio },
  ];

  public saving$: Observable<boolean> = this.createStoreLocationComponentStore.loading$;

  private readonly ngUnsubscribeState = new Subject<void>();
  private loadingSpinnerDialogRef: MatDialogRef<SpinnerDialogComponent>;
  private _isXLarge: boolean;
  private _isLarge: boolean
  private _isMedium: boolean;
  private _isSmall: boolean;
  private _isXSmall: boolean;

  private companyId: string;

  constructor(private formBuilder: UntypedFormBuilder,
    private location: Location,
    private readonly createStoreLocationComponentStore: CreateStoreLocationComponentStore,
    private route: ActivatedRoute,
    private router: Router,
    private readonly snackBar: MatSnackBar,
    private readonly spinnerService: SpinnerService,
    private readonly responsive: BreakpointObserver,
  ) { }

  public ngOnInit(): void {
    this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge]).subscribe(
      result => {
        const breakpoints = result.breakpoints;

        this._isXLarge = false;
        this._isLarge = false;
        this._isMedium = false;
        this._isSmall = false;
        this._isXSmall = false;

        if (breakpoints[Breakpoints.XSmall]) {
          this._isXSmall = true;
        }
        else if (breakpoints[Breakpoints.Small]) {
          this._isSmall = true;
        }
        else if (breakpoints[Breakpoints.Medium]) {
          this._isMedium = true;
        }
        else if (breakpoints[Breakpoints.Large]) {
          this._isLarge = true;
        }
        else {
          this._isXLarge = true;
        }
      }
    );

    this.route.params.subscribe(params => {
      this.companyId = params.companyId;
    });

    this.storeLocationDetailsFormGroup = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(this.minNameLength), Validators.maxLength(this.maxNameLength)]],
      description: ['', [Validators.required, Validators.minLength(this.minDescriptionLength), Validators.maxLength(this.maxDescriptionLength)]],
      addressLine1: ['', [Validators.required, Validators.minLength(this.minAddressLineLength), Validators.maxLength(this.maxAddressLineLength)]],
      addressLine2: ['', [Validators.maxLength(this.maxAddressLineLength)]],
      postalCode: ['', [Validators.required, Validators.minLength(this.minPostalCodeLength), Validators.maxLength(this.maxPostalCodeLength)]],
      city: ['', [Validators.required, Validators.minLength(this.minCityLength), Validators.maxLength(this.maxCityLength)]],
      country: ['', [Validators.required, Validators.maxLength(this.maxCountryLength)]],
      phoneNumber: ['', [Validators.required, Validators.minLength(this.minPhoneNumberLength), Validators.maxLength(this.maxPhoneNumberLength)]],
      type: ['', [Validators.required]],
      category: ['', [Validators.required]],
      optionVegan: [''],
      optionVegetarian: [''],
      optionVeganOptions: [''],
      optionVegetarianOptions: [''],
      optionGlutenFreeOptions: [''],
      optionBioOrganic: [''],
      optionBioDynamic: [''],
      optionZeroWaste: [''],
      optionPlasticFree: [''],
      optionPalmOilFree: [''],
    });

    this.createStoreLocationComponentStore.success$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((result) => {
      if (result) {
        this.snackBar.open("Store location created successfully", "Dismiss")
        this.router.navigate(['/store-locations']);
      }
    });

    this.createStoreLocationComponentStore.errorMessage$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe(error => {
      if (error instanceof MaximumActiveStoreLocationsExceededException) {
        this.snackBar.open("Can't create a new store location. You have already reached the maximum amount your subscription plan provides.", "Dismiss");
      }
      else if (error instanceof ValidationException) {
        const validationViolationMessage = error.violations.map(v => v.field + ' ' + v.message).join("\n");
        this.snackBar.open(validationViolationMessage, "Dismiss");
      }
    });

    this.createStoreLocationComponentStore.loaded$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((loaded) => {
      this.hideSpinner(loaded);
    });
  }

  public cancel(): void {
    this.location.back();
  }

  public save(): void {
    this.storeLocationDetailsFormGroup.markAllAsTouched();

    if (this.storeLocationDetailsFormGroup.valid) {
      this.showSpinner();
      var createStoreLocation = new CreateStoreLocationModel();
      createStoreLocation.name = this.storeLocationDetailsFormGroup.value.name;
      createStoreLocation.description = this.storeLocationDetailsFormGroup.value.description;
      createStoreLocation.addressLine1 = this.storeLocationDetailsFormGroup.value.addressLine1;
      createStoreLocation.addressLine2 = this.storeLocationDetailsFormGroup.value.addressLine2;
      createStoreLocation.postalCode = this.storeLocationDetailsFormGroup.value.postalCode;
      createStoreLocation.city = this.storeLocationDetailsFormGroup.value.city;
      createStoreLocation.country = this.storeLocationDetailsFormGroup.value.country;
      createStoreLocation.phoneNumber = this.storeLocationDetailsFormGroup.value.phoneNumber;
      createStoreLocation.storeLocationType = this.storeLocationDetailsFormGroup.value.type;
      createStoreLocation.storeLocationCategory = this.storeLocationDetailsFormGroup.value.category;
      createStoreLocation.storeLocationOptions = this._getFormGroupStoreLocationOptions();

      this.createStoreLocationComponentStore.createStoreLocation({ companyId: this.companyId, createStoreLocation: createStoreLocation });
    }
  }

  public isXLarge(): boolean {
    return this._isXLarge;
  }

  public isLarge(): boolean {
    return this._isLarge;
  }

  public isMedium(): boolean {
    return this._isMedium;
  }

  public isSmall(): boolean {
    return this._isSmall;
  }

  public isXSmall(): boolean {
    return this._isXSmall;
  }

  private _getFormGroupStoreLocationOptions(): StoreLocationOption[] {
    let storeLocationOptions: Array<StoreLocationOption> = [];

    if (this.storeLocationDetailsFormGroup.value.optionVeganOptions) {
      storeLocationOptions.push(StoreLocationOption.VEGAN_OPTIONS);
    }
    if (this.storeLocationDetailsFormGroup.value.optionVegetarianOptions) {
      storeLocationOptions.push(StoreLocationOption.VEGETARIAN_OPTIONS);
    }
    if (this.storeLocationDetailsFormGroup.value.optionGlutenFreeOptions) {
      storeLocationOptions.push(StoreLocationOption.GLUTEN_FREE_OPTIONS);
    }
    if (this.storeLocationDetailsFormGroup.value.optionBioOrganic) {
      storeLocationOptions.push(StoreLocationOption.BIO_ORGANIC);
    }
    if (this.storeLocationDetailsFormGroup.value.optionBioDynamic) {
      storeLocationOptions.push(StoreLocationOption.BIO_DYNAMIC);
    }
    if (this.storeLocationDetailsFormGroup.value.optionZeroWaste) {
      storeLocationOptions.push(StoreLocationOption.ZERO_WASTE);
    }
    if (this.storeLocationDetailsFormGroup.value.optionPlasticFree) {
      storeLocationOptions.push(StoreLocationOption.PLASTIC_FREE);
    }
    if (this.storeLocationDetailsFormGroup.value.optionPalmOilFree) {
      storeLocationOptions.push(StoreLocationOption.PALM_OIL_FREE);
    }
    return storeLocationOptions;
  }

  private showSpinner() {
    this.loadingSpinnerDialogRef = this.spinnerService.show();
  }

  private hideSpinner(loaded: boolean) {
    if (loaded && this.loadingSpinnerDialogRef !== null) {
      this.spinnerService.hide(this.loadingSpinnerDialogRef);
      this.loadingSpinnerDialogRef = null;
    }
  }
}
