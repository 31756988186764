import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StoreUserModel } from 'src/app/models/store-user-model';

@Component({
  selector: 'app-store-user-details',
  templateUrl: './store-user-details.component.html',
  styleUrls: ['./store-user-details.component.scss']
})
export class StoreUserDetailsComponent implements OnInit {
  @Input()
  public storeUser: StoreUserModel;

  @Output()
  public onGenerateNewPassword = new EventEmitter();

  private _isXLarge: boolean;
  private _isLarge: boolean
  private _isMedium: boolean;
  private _isSmall: boolean;
  private _isXSmall: boolean;

  constructor(private readonly snackBar: MatSnackBar,
    private readonly responsive: BreakpointObserver,
  ) {
    // Do nothing
  }

  public ngOnInit(): void {
    this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge]).subscribe(
      result => {
        const breakpoints = result.breakpoints;

        this._isXLarge = false;
        this._isLarge = false;
        this._isMedium = false;
        this._isSmall = false;
        this._isXSmall = false;

        if (breakpoints[Breakpoints.XSmall]) {
          this._isXSmall = true;
        }
        else if (breakpoints[Breakpoints.Small]) {
          this._isSmall = true;
        }
        else if (breakpoints[Breakpoints.Medium]) {
          this._isMedium = true;
        }
        else if (breakpoints[Breakpoints.Large]) {
          this._isLarge = true;
        }
        else {
          this._isXLarge = true;
        }
      }
    );
  }

  public generateNewPassword(): void {
    this.onGenerateNewPassword.emit(null);
  }

  public onCopyClick(): void {
    this.snackBar.open("Copied store username to clipboard", "Dismiss", { duration: 1000 });
  }

  public isXLarge(): boolean {
    return this._isXLarge;
  }

  public isLarge(): boolean {
    return this._isLarge;
  }

  public isMedium(): boolean {
    return this._isMedium;
  }

  public isSmall(): boolean {
    return this._isSmall;
  }

  public isXSmall(): boolean {
    return this._isXSmall;
  }
}
