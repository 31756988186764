import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BusinessAccountModel } from 'src/app/models/business-account-model';

@Component({
  selector: 'app-company-edit',
  templateUrl: './company-edit.component.html',
  styleUrls: ['./company-edit.component.scss']
})
export class CompanyEditComponent implements OnInit {
  @Input()
  public businessAccount: BusinessAccountModel;

  @Input()
  public companyDetailsFormGroup: UntypedFormGroup;

  @Input()
  public minNameLength: number;
  @Input()
  public maxNameLength: number;
  @Input()
  public minDescriptionLength: number;
  @Input()
  public maxDescriptionLength: number;
  @Input()
  public minEmailLength: number;
  @Input()
  public maxEmailLength: number;
  @Input()
  public minUrlLength: number;
  @Input()
  public maxUrlLength: number;

  @Output()
  public onCancelPressed = new EventEmitter();

  @Output()
  public onSavePressed = new EventEmitter();

  private _isXLarge: boolean;
  private _isLarge: boolean
  private _isMedium: boolean;
  private _isSmall: boolean;
  private _isXSmall: boolean;

  constructor(private readonly responsive: BreakpointObserver,) {
    // Do nothing
  }

  public ngOnInit(): void {
    this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge]).subscribe(
      result => {
        const breakpoints = result.breakpoints;

        this._isXLarge = false;
        this._isLarge = false;
        this._isMedium = false;
        this._isSmall = false;
        this._isXSmall = false;

        if (breakpoints[Breakpoints.XSmall]) {
          this._isXSmall = true;
        }
        else if (breakpoints[Breakpoints.Small]) {
          this._isSmall = true;
        }
        else if (breakpoints[Breakpoints.Medium]) {
          this._isMedium = true;
        }
        else if (breakpoints[Breakpoints.Large]) {
          this._isLarge = true;
        }
        else {
          this._isXLarge = true;
        }
      }
    );

    if (this.businessAccount !== null) {
      this.companyDetailsFormGroup.patchValue({ 'id': this.businessAccount.company.id });
      this.companyDetailsFormGroup.patchValue({ 'companyName': this.businessAccount.company.name });
      this.companyDetailsFormGroup.patchValue({ 'description': this.businessAccount.company.description });
      this.companyDetailsFormGroup.patchValue({ 'website': this.businessAccount.company.url });
      this.companyDetailsFormGroup.patchValue({ 'companyEmail': this.businessAccount.company.email });
    }
  }

  public cancel(): void {
    this.onCancelPressed.emit(null);
  }

  public save(): void {
    this.onSavePressed.emit(null);
  }

  public isXLarge(): boolean {
    return this._isXLarge;
  }

  public isLarge(): boolean {
    return this._isLarge;
  }

  public isMedium(): boolean {
    return this._isMedium;
  }

  public isSmall(): boolean {
    return this._isSmall;
  }

  public isXSmall(): boolean {
    return this._isXSmall;
  }
}
