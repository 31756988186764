import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { BusinessAccountModel } from '../models/business-account-model';
import { catchError, map } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { DeleteBusinessAccountModel } from '../models/delete-business-account-model';
import { ActiveSubscriptionException } from '../core/exceptions/active-subscription-exception';
import { ValidationException } from '../core/exceptions/validation-exception';
import { UnknownErrorException } from '../core/exceptions/unknown-error-exception';

@Injectable({
    providedIn: 'root',
})
export class BusinessAccountService {
    constructor(private http: HttpClient) { }

    getBusinessAccount(): Observable<BusinessAccountModel> {
        const httpOptions = {
            headers: new HttpHeaders({
                ContentType: 'application/json',
            }),
        };

        const url = environment.apiUrl + '/api/partner/businessaccounts';
        const result = this.http.get<BusinessAccountModel>(url, httpOptions);
        return result.pipe(catchError(this.handleError<BusinessAccountModel>('getBusinessAccount', null)));
    }

    deleteBusinessAccount(deleteBusinessAccount: DeleteBusinessAccountModel): Observable<boolean> {
        const httpOptions = {
            headers: new HttpHeaders({
                ContentType: 'application/json',
                observe: "response",
            }),
        };

        const url = environment.apiUrl + '/api/partner/businessaccounts/' + deleteBusinessAccount.id;
        const result = this.http.delete(url, httpOptions);
        return result.pipe(
            map(_ => true),
            catchError((response: HttpErrorResponse) => {
                if (response.status == 400) {
                    if (response.error.violations) {
                        const validationException = new ValidationException(response.error.violations);
                        return throwError(() => validationException);
                    }
                    return throwError(() => new UnknownErrorException());
                }
                if (response.status == 409) {
                    return throwError(() => new ActiveSubscriptionException());
                }
                return throwError(() => this.handleError('deleteBusinessAccount', response));
            }
            ));
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error(error);
            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }
}