import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { RRule } from 'rrule';
import { CampaignModel } from 'src/app/models/campaign-model';
import { environment } from 'src/environments/environment';
import { ShareLinkDialogComponent } from '../dialogs/share-link-dialog/share-link-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss']
})
export class CampaignComponent implements OnInit {
  @Input()
  public campaign: CampaignModel;

  @Output()
  public onDeletePressed = new EventEmitter();

  @Output()
  public onActivatePressed = new EventEmitter();

  @Output()
  public onDeactivatePressed = new EventEmitter();

  @Output()
  public onEditPressed = new EventEmitter();

  public recurrenceDisplayString: string;

  private _isXLarge: boolean;
  private _isLarge: boolean
  private _isMedium: boolean;
  private _isSmall: boolean;
  private _isXSmall: boolean;

  constructor(private readonly shareLinkDialog: MatDialog,
    private readonly responsive: BreakpointObserver,
  ) {
    // Do nothing
  }

  public ngOnInit(): void {
    this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge]).subscribe(
      result => {
        const breakpoints = result.breakpoints;

        this._isXLarge = false;
        this._isLarge = false;
        this._isMedium = false;
        this._isSmall = false;
        this._isXSmall = false;

        if (breakpoints[Breakpoints.XSmall]) {
          this._isXSmall = true;
        }
        else if (breakpoints[Breakpoints.Small]) {
          this._isSmall = true;
        }
        else if (breakpoints[Breakpoints.Medium]) {
          this._isMedium = true;
        }
        else if (breakpoints[Breakpoints.Large]) {
          this._isLarge = true;
        }
        else {
          this._isXLarge = true;
        }
      }
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.campaign?.recurrencePattern) {
      this.recurrenceDisplayString = new RRule(RRule.parseString(this.campaign.recurrencePattern)).toText();
    }
    else {
      this.recurrenceDisplayString = 'Does not repeat';
    }
  }

  public edit(): void {
    this.onEditPressed.emit(null);
  }

  public delete(): void {
    this.onDeletePressed.emit(null);
  }

  public canShowActivateAction(): boolean {
    const result = this.campaign?.isInactive();
    return result;
  }

  public canShowArchiveAction(): boolean {
    const result = this.campaign?.isActive() || this.campaign?.isPendingActivation();
    return result;
  }

  public canShowEditAction(): boolean {
    const result = this.campaign?.isActive() || this.campaign?.isPendingActivation();
    return result;
  }

  public canShowShareAction(): boolean {
    const result = this.campaign?.isActive();
    return result;
  }

  public activate(): void {
    this.onActivatePressed.emit(null);
  }

  public share(): void {
    this.shareLinkDialog.open(ShareLinkDialogComponent, {
      data: {
        title: "Share This",
        message: "Share this stamp card on your social media",
        data: this.getCampaignLink()
      }
    });
  }

  public isXLarge(): boolean {
    return this._isXLarge;
  }

  public isLarge(): boolean {
    return this._isLarge;
  }

  public isMedium(): boolean {
    return this._isMedium;
  }

  public isSmall(): boolean {
    return this._isSmall;
  }

  public isXSmall(): boolean {
    return this._isXSmall;
  }

  public getCampaignLink() : string {
    return environment.storefrontUrl + "/stamp-cards/" + this.campaign.id;
  }
}
