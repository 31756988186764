import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { businessAccountGetAction, unpaidInvoiceGetAction } from './stores/global/app.actions';
import { AuthorizationGuardService } from './services/authorization-guard.service';
import { PlanetVGJwtClaimNames } from './auth/planetvg-jwt-claim-names.model';
import { selectUnpaidInvoice } from './stores/global/app.selectors';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
 
  title = 'planetvg-business-portal';
  unpaidInvoice$ = this.store.select(selectUnpaidInvoice);

  constructor(
    private readonly authorizationService: AuthorizationGuardService,
    private readonly store: Store,
    private readonly router: Router,
  ) {
    // Do nothing
  }

  ngOnInit() {
    this.authorizationService.checkAuth().pipe()
      .subscribe(({ isAuthenticated, userData, accessToken, idToken }) => {
        if (isAuthenticated) {
          if ((PlanetVGJwtClaimNames.BusinessAccountId in userData)) {
            this.authorizationService.businessAccountId = userData[PlanetVGJwtClaimNames.BusinessAccountId];
            this.store.dispatch(businessAccountGetAction());
            this.store.dispatch(unpaidInvoiceGetAction({ companyId: userData[PlanetVGJwtClaimNames.CompanyId] }))
          }
        }
        else {
          this.authorizationService.reset();
        }
      });

    this.router.events
      .pipe()
      .subscribe(() => {
        // HACK: This is because we are using the mat-sidenav-content from Angular Material.
        // This overrides the anchorScrolling: 'enabled' and scrollPositionRestoration : 'top' settings.
        // So we need to do this manually.
        document.getElementsByTagName('mat-sidenav-content')[0]?.scrollTo(0, 0);
      }
    );
  }
}
