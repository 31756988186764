import { BreakpointObserver } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { TestimonialViewModel } from 'src/app/models/viewmodels/testimonial-view-model';
import { ResponsiveBaseComponent } from '../responsive-base/responsive-base.component';

@Component({
  selector: 'app-testimonials-logos-only-carousel',
  templateUrl: './testimonials-logos-only-carousel.component.html',
  styleUrl: './testimonials-logos-only-carousel.component.scss'
})
export class TestimonialsLogosOnlyCarouselComponent extends ResponsiveBaseComponent {
  private readonly _testimonials: TestimonialViewModel[] = [];

  constructor(responsive: BreakpointObserver,) {
    super(responsive);

    const testimonial = new TestimonialViewModel();
    testimonial.displayOrder = 0;
    testimonial.companyName = "Miss Nice Banana";
    testimonial.companyLogoUrl = "/assets/img/partners/miss-nice-banana.png";
    testimonial.description = "";
    this._testimonials.push(testimonial);


    const testimonial2 = new TestimonialViewModel();
    testimonial2.displayOrder = 1;
    testimonial2.companyName = "Beesechurgers";
    testimonial2.companyLogoUrl = "/assets/img/partners/beesechurgers.png";
    testimonial2.description = "";
    this._testimonials.push(testimonial2);

    const testimonial3 = new TestimonialViewModel();
    testimonial3.displayOrder = 2;
    testimonial3.companyName = "Vegan Deli Cioso";
    testimonial3.companyLogoUrl = "/assets/img/partners/vegan-deli-cioso.png";
    testimonial3.description = "";
    this._testimonials.push(testimonial3);


    const testimonial4 = new TestimonialViewModel();
    testimonial4.displayOrder = 2;
    testimonial4.companyName = "Vega Life";
    testimonial4.companyLogoUrl = "/assets/img/partners/vega-life.jpg";
    testimonial4.description = "";
    this._testimonials.push(testimonial4);

    this._testimonials.sort((lhs, rhs) => lhs.displayOrder - rhs.displayOrder);
  }

  public getTestimonials(): TestimonialViewModel[] {
    return this._testimonials;
  }
}