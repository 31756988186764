import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UpdateCampaignModel } from 'src/app/models/update-campaign-model';
import { VoucherDuration } from 'src/app/models/voucher-duration-enum';
import { MaximumActiveCampaignsExceededException } from '../../exceptions/maximum-active-campaigns-exceeded-exception';
import { ValidationException } from '../../exceptions/validation-exception';
import { Subject, takeUntil } from 'rxjs';
import { EndDateAfterStartDate } from '../../validators/start-end-date.validator';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { VoucherDurationP1M, VoucherDurationP3M, VoucherDurationP6M, VoucherDurationP1Y, VoucherDurationP2Y, VoucherDurationP3Y, CampaignModel } from 'src/app/models/campaign-model';
import { UpdateCampaignComponentStore } from 'src/app/stores/components/campaign-update/update-campaign.component-store';
import { SpinnerService } from 'src/app/services/spinner.service';
import { MatDialogRef } from '@angular/material/dialog';
import { SpinnerDialogComponent } from '../dialogs/spinner-dialog/spinner-dialog.component';
import { StampsObjectivesCantBeLowerThanOriginalException } from '../../exceptions/stamps-objective-cant-be-lower-than-original-exception';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

interface VoucherDurationViewModel {
  value: VoucherDuration,
  viewValue: string,
}

@Component({
  selector: 'app-campaign-edit',
  templateUrl: './campaign-edit.component.html',
  styleUrls: ['./campaign-edit.component.scss']
})
export class CampaignEditComponent {
  @Input()
  public campaignDetailsFormGroup: UntypedFormGroup;

  @Input()
  public campaign: CampaignModel;

  @Output()
  public onCancelPressed = new EventEmitter();

  @Output()
  public onSavePressed = new EventEmitter();

  @Output()
  public onUpdateSuccess = new EventEmitter();

  public minNameLength: number = 3;
  public maxNameLength: number = 32;
  public minDescriptionLength: number = 3;
  public maxDescriptionLength: number = 255;

  public voucherDurations: VoucherDurationViewModel[] = [
    { value: VoucherDuration.MONTH, viewValue: VoucherDurationP1M },
    { value: VoucherDuration.MONTHS3, viewValue: VoucherDurationP3M },
    { value: VoucherDuration.MONTHS6, viewValue: VoucherDurationP6M },
    { value: VoucherDuration.YEAR, viewValue: VoucherDurationP1Y },
    { value: VoucherDuration.YEARS2, viewValue: VoucherDurationP2Y },
    { value: VoucherDuration.YEARS3, viewValue: VoucherDurationP3Y },
  ];

  private readonly ngUnsubscribeState = new Subject<void>();
  private loadingSpinnerDialogRef: MatDialogRef<SpinnerDialogComponent>;
  private _isXLarge: boolean;
  private _isLarge: boolean
  private _isMedium: boolean;
  private _isSmall: boolean;
  private _isXSmall: boolean;

  private companyId: string;

  constructor(private formBuilder: UntypedFormBuilder,
    private updateCampaignComponentStore: UpdateCampaignComponentStore,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private spinnerService: SpinnerService,
    private readonly responsive: BreakpointObserver,
  ) { }

  public ngOnInit(): void {
    this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge]).subscribe(
      result => {
        const breakpoints = result.breakpoints;

        this._isXLarge = false;
        this._isLarge = false;
        this._isMedium = false;
        this._isSmall = false;
        this._isXSmall = false;

        if (breakpoints[Breakpoints.XSmall]) {
          this._isXSmall = true;
        }
        else if (breakpoints[Breakpoints.Small]) {
          this._isSmall = true;
        }
        else if (breakpoints[Breakpoints.Medium]) {
          this._isMedium = true;
        }
        else if (breakpoints[Breakpoints.Large]) {
          this._isLarge = true;
        }
        else {
          this._isXLarge = true;
        }
      }
    );

    this.route.params.subscribe(params => {
      this.companyId = params.companyId;
    });

    let startDate = new Date();
    let endDate = new Date();
    startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
    endDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
    endDate.setDate(endDate.getDate() + 90);

    this.campaignDetailsFormGroup = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(this.minNameLength), Validators.maxLength(this.maxNameLength)]],
      description: ['', [Validators.required, Validators.minLength(this.minDescriptionLength), Validators.maxLength(this.maxDescriptionLength)]],
      startDate: [startDate, Validators.required],
      endDate: [endDate, Validators.required],
      stampsObjective: ['5', [Validators.required, Validators.min(1)]],
      campaignRewardName: ['', [Validators.required, Validators.minLength(this.minNameLength), Validators.maxLength(this.maxNameLength)]],
      campaignRewardDescription: ['', [Validators.required, Validators.minLength(this.minDescriptionLength), Validators.maxLength(this.maxDescriptionLength)]],
      voucherDuration: [VoucherDuration.YEAR, Validators.required],
      recurrencePattern: [''],
    }, {
      validator: EndDateAfterStartDate('startDate', 'endDate')
    });

    if (this.campaign !== null) {
      this.campaignDetailsFormGroup.patchValue({ 'name': this.campaign.name });
      this.campaignDetailsFormGroup.patchValue({ 'description': this.campaign.description });
      this.campaignDetailsFormGroup.patchValue({ 'startDate': new Date(this.campaign.startDate) });
      this.campaignDetailsFormGroup.patchValue({ 'endDate': new Date(this.campaign.endDate) });
      this.campaignDetailsFormGroup.patchValue({ 'stampsObjective': this.campaign.stampsObjective });
      this.campaignDetailsFormGroup.patchValue({ 'campaignRewardName': this.campaign.campaignReward.name });
      this.campaignDetailsFormGroup.patchValue({ 'campaignRewardDescription': this.campaign.campaignReward.description });
      this.campaignDetailsFormGroup.patchValue({ 'voucherDuration': this.campaign.voucherDuration });
      this.campaignDetailsFormGroup.patchValue({ 'recurrencePattern': this.campaign.recurrencePattern });
    }

    this.updateCampaignComponentStore.campaign$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((result) => {
      this.hideSpinner(true);

      if (result) {
        this.snackBar.open("Stamp card updated successfully.", "Dismiss");
        this.router.navigate(['/companies/' + result.company.id + '/stamp-cards/' + result.id]);

        this.onUpdateSuccess.emit(null);
      }
    });

    this.updateCampaignComponentStore.errorMessage$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((errorMessage) => {
      this.hideSpinner(true);

      if (errorMessage !== null && errorMessage !== undefined) {
        if (errorMessage instanceof ValidationException) {
          const validationViolationMessage = errorMessage.violations.map(v => v.field + ' ' + v.message).join("\n");
          this.snackBar.open(validationViolationMessage, "Dismiss");
        }
        else if (errorMessage instanceof MaximumActiveCampaignsExceededException) {
          this.snackBar.open("Can't update the stamp card. You have already reached the maximum.", "Dismiss");
        }
        else if (errorMessage instanceof StampsObjectivesCantBeLowerThanOriginalException) {
          this.snackBar.open("Can't update the stamp card. Stamps Objective can't be lower than the original.", "Dismiss");
        }
        else {
          this.snackBar.open(errorMessage, "Dismiss");
        }
      }
    });
  }

  public cancel(): void {
    this.onCancelPressed.emit(null);
  }

  public save(): void {
    this.campaignDetailsFormGroup.markAllAsTouched();

    if (this.campaignDetailsFormGroup.valid) {
      this.showSpinner();

      const updateCampaign = new UpdateCampaignModel();
      updateCampaign.name = this.campaignDetailsFormGroup.value.name;
      updateCampaign.description = this.campaignDetailsFormGroup.value.description;
      updateCampaign.startDate = new Date(this.campaignDetailsFormGroup.value.startDate);
      updateCampaign.endDate = new Date(this.campaignDetailsFormGroup.value.endDate);
      updateCampaign.stampsObjective = this.campaignDetailsFormGroup.value.stampsObjective;
      updateCampaign.campaignRewardName = this.campaignDetailsFormGroup.value.campaignRewardName;
      updateCampaign.campaignRewardDescription = this.campaignDetailsFormGroup.value.campaignRewardDescription;
      updateCampaign.voucherDuration = this.campaignDetailsFormGroup.value.voucherDuration.toString();
      updateCampaign.recurrencePattern = this.campaignDetailsFormGroup.value.recurrencePattern;
      updateCampaign.campaignStatus = this.campaign.campaignStatus;

      this.updateCampaignComponentStore.updateCampaign({ companyId: this.companyId, campaignId: this.campaign.id, updateCampaign: updateCampaign });
    }
  }

  public onStampsSliderChanged($event): void {
    this.campaignDetailsFormGroup.patchValue({ stampsObjective: $event.value });
  }

  public onRecurrencePatternChanged($event): void {
    this.campaignDetailsFormGroup.patchValue({ recurrencePattern: $event });
  }

  public isXLarge(): boolean {
    return this._isXLarge;
  }

  public isLarge(): boolean {
    return this._isLarge;
  }

  public isMedium(): boolean {
    return this._isMedium;
  }

  public isSmall(): boolean {
    return this._isSmall;
  }

  public isXSmall(): boolean {
    return this._isXSmall;
  }

  private showSpinner(): void {
    this.loadingSpinnerDialogRef = this.spinnerService.show();
  }

  private hideSpinner(loaded: boolean): void {
    if (loaded && this.loadingSpinnerDialogRef !== null) {
      this.spinnerService.hide(this.loadingSpinnerDialogRef);
      this.loadingSpinnerDialogRef = null;
    }
  }
}
