<div [ngClass]="{
  'container-xlarge': isXLarge(),
  'container-large': isLarge(),
  'container-medium': isMedium(),
  'container-small': isSmall(),
  'container-xsmall': isXSmall()
}" [formGroup]="companyDetailsFormGroup">
  <mat-card appearance="outlined" class="mat-component-background mat-elevation-z2">
    <mat-card-header>
      <mat-card-title> Editing Company Details </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div [ngClass]="{
        'container-content-xlarge': isXLarge(),
        'container-content-large': isLarge(),
        'container-content-medium': isMedium(),
        'container-content-small': isSmall(),
        'container-content-xsmall': isXSmall()
      }">
        <div class="container-column">
          <mat-form-field appearance="outline">
            <mat-label>Company Name</mat-label>
            <input matInput placeholder="Ex. My Company B.V." formControlName="companyName" />
            <mat-error *ngIf="
                companyDetailsFormGroup
                  .get('companyName')
                  .hasError('minlength') &&
                companyDetailsFormGroup.get('companyName').touched
              ">
              <span>The minimum length for your company name is not met</span>
            </mat-error>
            <mat-error *ngIf="
                companyDetailsFormGroup
                  .get('companyName')
                  .hasError('maxlength') &&
                companyDetailsFormGroup.get('companyName').touched
              ">
              <span>The maximum length reached for your company name</span>
            </mat-error>
            <mat-error *ngIf="
                companyDetailsFormGroup
                  .get('companyName')
                  .hasError('required') &&
                companyDetailsFormGroup.get('companyName').touched
              ">
              <span>Company name is required</span>
            </mat-error>
            <mat-error *ngIf="
                    companyDetailsFormGroup
                      .get('companyName')
                      .errors?.companyNameTaken &&
                    companyDetailsFormGroup.get('companyName').touched
                  ">
              <span>Company name is already taken.</span>
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Description</mat-label>
            <textarea matInput placeholder="Ex. My company description" formControlName="description"
              style="height: 5em;"></textarea>
            <mat-error *ngIf="
                companyDetailsFormGroup
                  .get('description')
                  .hasError('minlength') &&
                companyDetailsFormGroup.get('description').touched
              ">
              <span>The minimum length of your company description is not met</span>
            </mat-error>
            <mat-error *ngIf="
                companyDetailsFormGroup
                  .get('description')
                  .hasError('maxlength') &&
                companyDetailsFormGroup.get('description').touched
              ">
              <span>The maximum length of your company description reached</span>
            </mat-error>
            <mat-error *ngIf="
                companyDetailsFormGroup
                  .get('description')
                  .hasError('required') &&
                companyDetailsFormGroup.get('description').touched
              ">
              <span>Description is required</span>
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Website</mat-label>
            <input matInput placeholder="Ex. www.example.com" formControlName="website" />
            <mat-error *ngIf="
                companyDetailsFormGroup.get('website').hasError('required') &&
                companyDetailsFormGroup.get('website').touched
              ">
              <span>Website is required</span>
            </mat-error>
            <mat-error *ngIf="
                companyDetailsFormGroup.get('website').hasError('pattern') &&
                companyDetailsFormGroup.get('website').touched
              ">
              <span>Not a valid url is provided</span>
            </mat-error>
            <mat-error *ngIf="
                companyDetailsFormGroup
                  .get('website')
                  .hasError('minlength') &&
                companyDetailsFormGroup.get('website').touched
              ">
              <span>The minimum length of your company website is not met</span>
            </mat-error>
            <mat-error *ngIf="
                companyDetailsFormGroup
                  .get('website')
                  .hasError('maxlength') &&
                companyDetailsFormGroup.get('website').touched
              ">
              <span>The maximum length of your company website reached</span>
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Company Email</mat-label>
            <input type="email" matInput placeholder="Ex. info@yourcompany.com" formControlName="companyEmail" />
            <mat-error *ngIf="
                companyDetailsFormGroup
                  .get('companyEmail')
                  .hasError('required') &&
                companyDetailsFormGroup.get('companyEmail').touched
              ">
              <span>Company email is required</span>
            </mat-error>
            <mat-error *ngIf="
                companyDetailsFormGroup
                  .get('companyEmail')
                  .hasError('pattern') &&
                companyDetailsFormGroup.get('companyEmail').touched
              ">
              <span>Not a valid email is provided</span>
            </mat-error>
            <mat-error *ngIf="
                companyDetailsFormGroup
                  .get('companyEmail')
                  .hasError('minlength') &&
                companyDetailsFormGroup.get('companyEmail').touched
              ">
              <span>The minimum length of your company email is not met</span>
            </mat-error>
            <mat-error *ngIf="
                companyDetailsFormGroup
                  .get('companyEmail')
                  .hasError('maxlength') &&
                companyDetailsFormGroup.get('companyEmail').touched
              ">
              <span>The maximum length of your company email reached</span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions class="container-actions">
      <button mat-icon-button (click)="cancel()" matTooltip="Cancel">
        <mat-icon>cancel</mat-icon>
      </button>
      <button mat-icon-button (click)="save()" matTooltip="Save">
        <mat-icon>save</mat-icon>
      </button>
    </mat-card-actions>
  </mat-card>
</div>