import { Component, Input, SimpleChanges } from '@angular/core';
import { OptionBioDynamic, OptionBioOrganic, OptionGlutenFreeOptions, OptionPalmOilFree, OptionPlasticFree, OptionVeganOptions, OptionVegetarianOptions, OptionZeroWaste, StoreLocationOption } from 'src/app/models/store-location-option-model';

export interface StoreLocationOptionViewModel {
  value: StoreLocationOption,
  viewValue: string,
  displayOrder: number
}

@Component({
  selector: 'app-store-location-options',
  templateUrl: './store-location-options.component.html',
  styleUrls: ['./store-location-options.component.scss']
})
export class StoreLocationOptionsComponent {
  @Input()
  storeLocationOptions: Array<StoreLocationOption> = [];

  storeLocationOptionViewModel: StoreLocationOptionViewModel[];

  ngOnInit(): void {
    // Do nothing
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.storeLocationOptions.currentValue
      && this.storeLocationOptions
    ) {
      this.storeLocationOptionViewModel = this._getStoreLocationOptionViewModel();
    }
  }

  private _getStoreLocationOptionViewModel(): StoreLocationOptionViewModel[] {
    let storeLocationOptionViewModel: Array<StoreLocationOptionViewModel> = [];

    for (const storeLocationOption of this.storeLocationOptions) {
      switch (storeLocationOption) {
        case StoreLocationOption.VEGAN_OPTIONS:
          storeLocationOptionViewModel.push({ displayOrder: 0, value: StoreLocationOption.VEGAN_OPTIONS, viewValue: OptionVeganOptions });
          break;
        case StoreLocationOption.VEGETARIAN_OPTIONS:
          storeLocationOptionViewModel.push({ displayOrder: 1, value: StoreLocationOption.VEGETARIAN_OPTIONS, viewValue: OptionVegetarianOptions });
          break;
        case StoreLocationOption.GLUTEN_FREE_OPTIONS:
          storeLocationOptionViewModel.push({ displayOrder: 2, value: StoreLocationOption.GLUTEN_FREE_OPTIONS, viewValue: OptionGlutenFreeOptions });
          break;
        case StoreLocationOption.BIO_ORGANIC:
          storeLocationOptionViewModel.push({ displayOrder: 3, value: StoreLocationOption.BIO_ORGANIC, viewValue: OptionBioOrganic });
          break;
        case StoreLocationOption.BIO_DYNAMIC:
          storeLocationOptionViewModel.push({ displayOrder: 4, value: StoreLocationOption.BIO_DYNAMIC, viewValue: OptionBioDynamic });
          break;
        case StoreLocationOption.ZERO_WASTE:
          storeLocationOptionViewModel.push({ displayOrder: 5, value: StoreLocationOption.ZERO_WASTE, viewValue: OptionZeroWaste });
          break;
        case StoreLocationOption.PLASTIC_FREE:
          storeLocationOptionViewModel.push({ displayOrder: 6, value: StoreLocationOption.PLASTIC_FREE, viewValue: OptionPlasticFree });
          break;
        case StoreLocationOption.PALM_OIL_FREE:
          storeLocationOptionViewModel.push({ displayOrder: 7, value: StoreLocationOption.PALM_OIL_FREE, viewValue: OptionPalmOilFree });
          break;
      }
    }

    storeLocationOptionViewModel.sort((lhs, rhs) => lhs.displayOrder - rhs.displayOrder);
    return storeLocationOptionViewModel;
  }
}
