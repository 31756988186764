<div [ngClass]="{
  'container-xlarge': isXLarge(),
  'container-large': isLarge(),
  'container-medium': isMedium(),
  'container-small': isSmall(),
  'container-xsmall': isXSmall()
}">
  <h1>Thank you for joining our community!</h1>
  <img src="/assets/img/planetvg_icon.png" alt="planetvg-logo" />

  <mat-card appearance="outlined" class="mat-component-background mat-elevation-z2">
    <mat-card-header>
      <mat-card-title>
        <h1>What's next on the menu?</h1>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="container-content">
        <mat-list>
          <mat-list-item [ngClass]="{
                  'list-item-xsmall': isXSmall()
                }">
            <span matListItemIcon>
              <mat-icon color="primary">eco</mat-icon>
            </span>
            <div matListItemTitle class="list-item-title">Sign in to the Planet VG Partner Portal.</div>
          </mat-list-item>
          <mat-list-item [ngClass]="{
                  'list-item-xsmall': isXSmall()
                }">
            <span matListItemIcon>
              <mat-icon color="primary">eco</mat-icon>
            </span>
            <div matListItemTitle class="list-item-title">Add your public company profile.</div>
          </mat-list-item>
          <mat-list-item [ngClass]="{
                  'list-item-xsmall': isXSmall()
                }">
            <span matListItemIcon>
              <mat-icon color="primary">eco</mat-icon>
            </span>
            <div matListItemTitle class="list-item-title">Add your store location.</div>
          </mat-list-item>
        </mat-list>
      </div>
      <div>
        <p>The Planet VG Chefs are busy preparing your subscription plan.</p>
        <p>You will be automatically redirected to the dashboard in {{ timeLeft }} seconds.</p>
      </div>
    </mat-card-content>
    <mat-card-actions class="container-buttons">
      <div *ngIf="showNavigateButton$ | async">
        <button mat-raised-button color="primary" (click)="navigateManually()" cdkFocusInitial>
          <mat-icon>dashboard</mat-icon>
          Dashboard
        </button>
      </div>
    </mat-card-actions>
  </mat-card>
</div>
<app-bottom-menu></app-bottom-menu>