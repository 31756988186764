import { Component } from '@angular/core';
import { TestimonialViewModel } from 'src/app/models/viewmodels/testimonial-view-model';
import { ResponsiveBaseComponent } from '../responsive-base/responsive-base.component';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-testimonials-carousel',
  templateUrl: './testimonials-carousel.component.html',
  styleUrl: './testimonials-carousel.component.scss'
})
export class TestimonialsCarouselComponent extends ResponsiveBaseComponent {
  private readonly _testimonials: TestimonialViewModel[] = [];

  constructor(responsive: BreakpointObserver,) {
    super(responsive);

    const testimonial = new TestimonialViewModel();
    testimonial.displayOrder = 0;
    testimonial.companyName = "Miss Nice Banana";
    testimonial.companyLogoUrl = "/assets/img/partners/miss-nice-banana.png";
    testimonial.description = "As a conscious entrepreneur it feels so good to be part of a platform that is made by vegans for vegans. Because you share the same values you feel the instant connection and engagement. The makers of Planet VG really managed to let your company shine on this platform and to form a community! I am a proud partner!";
    this._testimonials.push(testimonial);


    const testimonial2 = new TestimonialViewModel();
    testimonial2.displayOrder = 1;
    testimonial2.companyName = "Beesechurgers";
    testimonial2.companyLogoUrl = "/assets/img/partners/beesechurgers.png";
    testimonial2.description = "Since we started using PlanetVG at Beesechurgers, we've finally had a platform to cater to our loyal customers. The app makes it so easy to share exclusive deals and rewards, and the seamless user experience keeps our clients happy. It's the perfect tool for driving engagement and building loyalty — it’s like having a marketing assistant in our pocket!";
    this._testimonials.push(testimonial2);

    const testimonial3 = new TestimonialViewModel();
    testimonial3.displayOrder = 2;
    testimonial3.companyName = "Vegan Deli Cioso";
    testimonial3.companyLogoUrl = "/assets/img/partners/vegan-deli-cioso.png";
    testimonial3.description = "Implementing this loyalty and promotions app at Vegan Deli Cioso has been one of the best decisions we've made. We are a new business and need to have accurrate data on our new customers. It's incredibly user-friendly and has helped us showcase our vegan offerings to a wider audience!";
    this._testimonials.push(testimonial3);

    this._testimonials.sort((lhs, rhs) => lhs.displayOrder - rhs.displayOrder);
  }

  public getTestimonials(): TestimonialViewModel[] {
    return this._testimonials;
  }
}
