import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, of, Subject, takeUntil } from 'rxjs';
import { UploadPhotoModel } from 'src/app/models/upload-photo-model';
import * as _ from 'lodash';
import { CampaignLogoComponentStore } from 'src/app/stores/components/campaign-logo/campaign-logo.component-store';
import { CampaignModel } from 'src/app/models/campaign-model';

@Component({
  selector: 'app-campaign-logo',
  templateUrl: './campaign-logo.component.html',
  styleUrls: ['./campaign-logo.component.scss']
})
export class CampaignLogoComponent implements OnInit {
  readonly max_size = 538 * 1024;
  readonly allowed_types = ['image/png', 'image/jpeg'];
  readonly max_height = 512;
  readonly max_width = 512;
  readonly placeholderUrl = 'https://via.placeholder.com/512';

  @Input()
  campaign: CampaignModel;

  ngUnsubscribeState = new Subject<void>();

  isImageSaved: boolean;

  campaignLogoUrl$: Observable<string>;

  constructor(private campaignLogoComponentStore: CampaignLogoComponentStore,
    private snackBar: MatSnackBar
  ) {
    // Do nothing
  }

  ngOnInit(): void {
    this.campaignLogoComponentStore.logo$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe(logo => {
      this.snackBar.dismiss();
      if (logo !== null && logo !== undefined) {
        this.isImageSaved = true;
        this.campaignLogoUrl$ = of(logo.url);
      }
      else {
        this.isImageSaved = false;
        this.campaignLogoUrl$ = of(this.placeholderUrl);
        if (this.campaign
          && (this.campaign.isActive() || this.campaign.isPendingActivation)) {
          this.snackBar.open('Please upload a campaign logo.', 'Dismiss');
        }
      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribeState.next();
    this.ngUnsubscribeState.complete();
  }

  ngOnChanges(changes) {
    if (!changes.campaign.firstChange) {
      if (this.campaign) {
        this.campaignLogoComponentStore.getCampaignLogo({ companyId: this.campaign.company.id, campaignId: this.campaign.id });
      }
    }
  }

  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      if (fileInput.target.files[0].size > this.max_size) {
        var errorMessage =
          'Maximum size allowed is ' + Math.floor(this.max_size / 1000) + 'Kb';
        this.snackBar.open(errorMessage, "Dismiss");
        return false;
      }

      if (!_.includes(this.allowed_types, fileInput.target.files[0].type)) {
        var errorMessage = 'Only Images are allowed ( JPG | PNG )';
        this.snackBar.open(errorMessage, "Dismiss");
        return false;
      }

      var uploadPhoto = new UploadPhotoModel();

      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];
          if (img_height > this.max_height && img_width > this.max_width) {
            var errorMessage =
              'Maximum dimensions allowed ' +
              this.max_height +
              '*' +
              this.max_width +
              'px';
            this.snackBar.open(errorMessage, "Dismiss");
            return false;
          } else {
            const imgBase64Path = e.target.result;
            uploadPhoto.fileContentBase64 = imgBase64Path.split(',')[1];
            this.campaignLogoComponentStore.uploadCampaignLogo({ companyId: this.campaign.company.id, campaignId: this.campaign.id, uploadPhoto: uploadPhoto });

            return true;
          }
        };
      };

      var logo = fileInput.target.files[0];

      uploadPhoto.title = 'Campaign Logo';
      uploadPhoto.fileName = 'logo' + this._determineFileExtension(logo.type);

      reader.readAsDataURL(logo);
      return true;
    }
    return true;
  }

  removeImage() {
    this.campaignLogoComponentStore.deleteCampaignLogo({ companyId: this.campaign.company.id, campaignId: this.campaign.id });
  }

  _determineFileExtension(fileType: string): string {
    switch (fileType) {
      case 'image/jpg':
      case 'image/jpeg':
        return '.jpg';
      case 'image/png':
        return '.png';
    }
    throw new Error('Unsupported file type:' + fileType);
  }
}
