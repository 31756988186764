import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { catchError, map, Observable, switchMap } from "rxjs";
import { PaymentRequestModel } from "src/app/models/payment-request-model";
import { CheckoutService } from "src/app/services/checkout.service";
import { CheckoutFormState } from "./checkout.state";
import { PaymentInformationModel } from "src/app/models/payment-information-model";

const initialState: CheckoutFormState = {
    loading: false,
    loaded: false,
    success: false,
    errorMessage: null,
    paymentRequestData: null,
    paymentInformation: null,
};

@Injectable({ providedIn: 'root' })
export class CheckoutFormComponentStore extends ComponentStore<CheckoutFormState> {
    constructor(private readonly checkoutService: CheckoutService) {
        super(initialState)
    }

    readonly paymentInformation$: Observable<PaymentInformationModel> = this.select(state => state.paymentInformation);
    readonly loading$: Observable<boolean> = this.select(state => state.loading);
    readonly loaded$: Observable<boolean> = this.select(state => state.loaded);
    readonly success$: Observable<boolean> = this.select(state => state.success);
    readonly errorMessage$: Observable<any> = this.select(state => state.errorMessage);

    readonly setInitial = this.updater((_: CheckoutFormState) => {
        return {
            ...initialState
        };
    });

    readonly setLoading = this.updater((state: CheckoutFormState) => {
        return {
            ...state,
            loading: true,
            loaded: false
        };
    });

    readonly setLoaded = this.updater((state: CheckoutFormState) => {
        return {
            ...state,
            loading: false,
            loaded: true
        };
    });

    readonly setError = this.updater((state: CheckoutFormState, value: { errorMessage: string }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: false,
            errorMessage: value.errorMessage
        };
    });

    readonly setSuccess = this.updater((state: CheckoutFormState, value: { paymentInformation: PaymentInformationModel }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: true,
            errorMessage: null,
            paymentInformation: value.paymentInformation,
        };
    });

    readonly doCheckout = this.effect((checkoutParams$: Observable<{ paymentRequestData: PaymentRequestModel }>) => {
        this.setLoading();
        return checkoutParams$.pipe(
            switchMap(params => {
                return this.checkoutService.checkoutShoppingCart(params.paymentRequestData).pipe(
                    map((paymentInformation) => {
                        this.setSuccess({ paymentInformation: paymentInformation });
                        this.setLoaded();
                        this.setInitial();
                    }),
                    catchError(async (error) => {
                        this.setError({ errorMessage: error });
                        this.setLoaded();
                        this.setInitial();
                    })
                );
            })
        )
    });
}