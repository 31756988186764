import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { SpinnerDialogComponent } from 'src/app/core/components/dialogs/spinner-dialog/spinner-dialog.component';
import { BusinessAccountModel } from 'src/app/models/business-account-model';
import { SpinnerService } from 'src/app/services/spinner.service';
import { StoreLocationsComponentStore } from 'src/app/stores/components/store-locations/store-locations.component-store';
import { selectBusinessAccount, selectHasSubscriptionPlanOrder, selectUnpaidInvoice } from 'src/app/stores/global/app.selectors';

@Component({
  selector: 'app-store-locations-page',
  templateUrl: './store-locations-page.component.html',
  styleUrls: ['./store-locations-page.component.scss']
})
export class StoreLocationsPageComponent implements OnInit {
  public businessAccount$: Observable<BusinessAccountModel> = this.store.select(selectBusinessAccount);
  public unpaidInvoice$ = this.store.select(selectUnpaidInvoice);
  public viewModel$ = this.storeLocationsStore.viewModel$;
  public showAdd$: Observable<boolean>;
  
  public currentPage: number = 0;
  public pageSize: number = 20;
  public pageSizeOptions = [this.pageSize];
  
  private readonly ngUnsubscribeState = new Subject<void>();
  private loadingSpinnerDialogRef: MatDialogRef<SpinnerDialogComponent>;
  private _isXLarge: boolean;
  private _isLarge: boolean
  private _isMedium: boolean;
  private _isSmall: boolean;
  private _isXSmall: boolean;

  private businessAccount: BusinessAccountModel;

  constructor(
    private readonly store: Store<any>,
    private readonly storeLocationsStore: StoreLocationsComponentStore,
    private readonly router: Router,
    private readonly spinnerService: SpinnerService,
    private readonly responsive: BreakpointObserver,
  ) { }

  public ngOnInit(): void {
    this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge]).subscribe(
          result => {
            const breakpoints = result.breakpoints;
    
            this._isXLarge = false;
            this._isLarge = false;
            this._isMedium = false;
            this._isSmall = false;
            this._isXSmall = false;
    
            if (breakpoints[Breakpoints.XSmall]) {
              this._isXSmall = true;
            }
            else if (breakpoints[Breakpoints.Small]) {
              this._isSmall = true;
            }
            if (breakpoints[Breakpoints.Medium]) {
              this._isMedium = true;
            }
            else if (breakpoints[Breakpoints.Large]) {
              this._isLarge = true;
            }
            else {
              this._isXLarge = true;
            }
          }
        );

    this.store.select(selectBusinessAccount).pipe(filter(result => Boolean(result))).subscribe(businessAccount => {
      const gettingStartedCompanyDetails: boolean = !businessAccount.company.name || businessAccount.company.name === '';
      const gettingStartedCompanyLogo: boolean = !businessAccount.company.logo;
      if (gettingStartedCompanyDetails || gettingStartedCompanyLogo) {
        this.router.navigate(['/getting-started']);
      }
      else {
        this.businessAccount = businessAccount;

        this.storeLocationsStore.setInitial();
        this.showSpinner();
        this.storeLocationsStore.getStoreLocations({ companyId: businessAccount.company.id, currentPage: this.currentPage, pageSize: this.pageSize });
      }
    });

    this.showAdd$ = this.store.select(selectHasSubscriptionPlanOrder).pipe(takeUntil(this.ngUnsubscribeState));

    this.storeLocationsStore.loaded$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((loaded) => {
      this.hideSpinner(loaded);
    });
  }

  public ngOnDestroy() : void {
    this.storeLocationsStore.setInitial();
    this.ngUnsubscribeState.next();
    this.ngUnsubscribeState.complete();
  }

  public onPageEvent(event?: PageEvent) : PageEvent {
    this.showSpinner();
    this.storeLocationsStore.getStoreLocations({ companyId: this.businessAccount.company.id, currentPage: event.pageIndex, pageSize: event.pageSize });
    return event;
  }

  public create() : void {
    this.router.navigate(['/companies/', this.businessAccount.company.id, 'store-locations', 'create']);
  }

  public getDisplayedColumns() : string[] {
    if(this.isSmall() || this.isXSmall()){
      return ['id', 'name', 'storeLocationStatus'];  
    }
    return ['id', 'name', 'addressLine1', 'city', 'phoneNumber', 'storeLocationStatus'];
  }

  public isXLarge(): boolean {
    return this._isXLarge;
  }

  public isLarge(): boolean {
    return this._isLarge;
  }

  public isMedium(): boolean {
    return this._isMedium;
  }

  public isSmall(): boolean {
    return this._isSmall;
  }

  public isXSmall(): boolean {
    return this._isXSmall;
  }

  private showSpinner() : void {
    this.loadingSpinnerDialogRef = this.spinnerService.show();
  }

  private hideSpinner(loaded: boolean) : void {
    if (loaded && this.loadingSpinnerDialogRef !== null) {
      this.spinnerService.hide(this.loadingSpinnerDialogRef);
      this.loadingSpinnerDialogRef = null;
    }
  }
}
