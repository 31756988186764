import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { catchError, map, Observable, switchMap, tap } from "rxjs";
import { PhotoModel } from "src/app/models/photo-model";
import { UploadPhotoModel } from "src/app/models/upload-photo-model";
import { CampaignLogoService } from "src/app/services/campaign-logo.service";
import { CampaignLogoState } from "./campaign-logo.state";

const initialState: CampaignLogoState = {
    loading: false,
    loaded: false,
    success: false,
    errorMessage: null,
    logo: null,
    uploadPhoto: null
}

@Injectable({
    providedIn: 'root'
})
export class CampaignLogoComponentStore extends ComponentStore<CampaignLogoState> {
    constructor(private readonly campaignLogoService: CampaignLogoService) {
        super(initialState);
    }

    readonly logo$: Observable<PhotoModel> = this.select(state => state.logo);
    readonly uploadPhoto$: Observable<UploadPhotoModel> = this.select(state => state.uploadPhoto);
    readonly loading$: Observable<boolean> = this.select(state => state.loading);
    readonly loaded$: Observable<boolean> = this.select(state => state.loaded);
    readonly success$: Observable<boolean> = this.select(state => state.success);
    readonly errorMessage$: Observable<any> = this.select(state => state.errorMessage);

    readonly viewModel$ = this.select(
        this.logo$,
        this.uploadPhoto$,
        this.loaded$,
        this.loading$,
        this.success$,
        this.errorMessage$,
        (logo, uploadPhoto, loaded, loading, success, errorMessage) => ({
            logo, uploadPhoto, loaded, loading, success, errorMessage
        })
    );

    readonly setInitial = this.updater((_: CampaignLogoState) => {
        return {
            ...initialState
        };
    });

    readonly setLoading = this.updater((state: CampaignLogoState) => {
        return {
            ...state,
            loading: true,
            loaded: false,
        };
    });

    readonly setLoaded = this.updater((state: CampaignLogoState) => {
        return {
            ...state,
            loading: false,
            loaded: true,
        };
    });

    readonly updateCampaignLogoState = this.updater((state: CampaignLogoState, value: {
        logo: PhotoModel
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: true,
            logo: value.logo,
            uploadPhoto : null
        };
    });

    readonly setError = this.updater((state: CampaignLogoState, value: {
        errorMessage: string
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: false,
            errorMessage: value.errorMessage,
        };
    });

    readonly getCampaignLogo = this.effect((params$: Observable<{ companyId: string, campaignId: number }>) => {
        this.setLoading();
        return params$.pipe(
            switchMap((params => this.campaignLogoService.getCampaignLogo(params.companyId, params.campaignId).pipe(
                tap({
                    next: (logo) => {
                        this.updateCampaignLogoState({ logo: logo });
                        this.setLoaded();
                    },
                    error: (e) => {
                        throw e;
                    }
                }),
                catchError(async (error) => {
                    this.setError({ errorMessage: error })
                    this.setLoaded();
                    this.setInitial();
                })
            ))
            )
        );
    });

    readonly uploadCampaignLogo = this.effect((params$: Observable<{ companyId: string, campaignId: number, uploadPhoto: UploadPhotoModel }>) => {
        this.setLoading();
        return params$.pipe(
            switchMap((params => this.campaignLogoService.uploadCampaignLogo(params.companyId, params.campaignId, params.uploadPhoto).pipe(
                tap({
                    next: (logo) => {
                        this.updateCampaignLogoState({ logo: logo });
                        this.setLoaded();
                    },
                    error: (e) => {
                        throw e;
                    }
                }),
                catchError(async (error) => {
                    this.setError({ errorMessage: error })
                    this.setLoaded();
                    this.setInitial();
                })
            ))
            )
        );
    });

    readonly deleteCampaignLogo = this.effect((params$: Observable<{ companyId: string, campaignId: number }>) => {
        this.setLoading();
        return params$.pipe(
            switchMap((params => this.campaignLogoService.deleteCampaignLogo(params.companyId, params.campaignId).pipe(
                map(() => {
                    this.updateCampaignLogoState({ logo: null });
                    this.setLoaded();
                }),
                catchError(async (error) => {
                    this.setError({ errorMessage: error })
                    this.setLoaded();
                    this.setInitial();
                })
            ))
            )
        );
    });

    readonly reset = this.effect((params$: Observable<{}>) => {
        return params$.pipe(
            map(() => {
                
                this.setInitial();
                
            })
        );
    });
}