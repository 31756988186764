<div class="container">
  <div *ngIf="unpaidInvoice$ | async">
    <app-unpaid-invoice-banner [unpaidInvoice]="unpaidInvoice$ | async"></app-unpaid-invoice-banner>
  </div>

  <div *ngIf="showAdd$ | async; else showShop">
    <div [ngClass]="{
      'container-content-xlarge': isXLarge(),
      'container-content-large': isLarge(),
      'container-content-medium': isMedium(),
      'container-content-small': isSmall(),
      'container-content-xsmall': isXSmall()
    }">
      <div class="container-content-add">
        <button mat-raised-button (click)="create()" color="primary">
          <mat-icon>add_business</mat-icon>
          New
        </button>
      </div>
      <div class="container-content-grid">
        <table description="Overview of store locations" mat-table [dataSource]="(viewModel$ | async)?.storeLocations"
          class="mat-component-background mat-elevation-z2">
          <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

          <!-- Id Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>Id</th>
            <td mat-cell *matCellDef="let element">{{ element.id }}</td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element">{{ element.name }}</td>
          </ng-container>

          <!-- AddressLine1 Column -->
          <ng-container matColumnDef="addressLine1" *ngIf="!isSmall() && !isXSmall()">
            <th mat-header-cell *matHeaderCellDef>Address</th>
            <td mat-cell *matCellDef="let element">{{ element.addressLine1 }}</td>
          </ng-container>

          <!-- City Column -->
          <ng-container matColumnDef="city" *ngIf="!isSmall() && !isXSmall()">
            <th mat-header-cell *matHeaderCellDef>City</th>
            <td mat-cell *matCellDef="let element">{{ element.city }}</td>
          </ng-container>

          <!-- PhoneNumber Column -->
          <ng-container matColumnDef="phoneNumber" *ngIf="!isSmall() && !isXSmall()">
            <th mat-header-cell *matHeaderCellDef>Phone Number</th>
            <td mat-cell *matCellDef="let element">{{ element.phoneNumber }}</td>
          </ng-container>

          <!-- StoreLocation Status Column -->
          <ng-container matColumnDef="storeLocationStatus">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let element">
              {{ element.storeLocationStatus }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
          <tr mat-row *matRowDef="let row; columns: getDisplayedColumns()" [routerLink]="[
            '/companies',
            row.company.id,
            'store-locations',
            row.id
          ]"></tr>
        </table>
        <div class="container-paginator">
          <mat-paginator [length]="(viewModel$ | async)?.totalElements" [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions" (page)="onPageEvent($event)" [hidePageSize]="true"
            class="mat-component-background mat-elevation-z2">
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #showShop>
  <app-buy-subscription-plan-banner></app-buy-subscription-plan-banner>
</ng-template>