<div class="container">
  <mat-card appearance="outlined" class="mat-component-background mat-elevation-z2">
    <mat-card-header>
      <mat-card-title> Create New Promotion </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-horizontal-stepper [linear]="true" #stepper class="mat-component-background">
        <mat-step [stepControl]="promotionDetailsFormGroup" [editable]="false" [completed]="false">
          <ng-template matStepLabel>Fill out your promotion details</ng-template>
          <form [formGroup]="promotionDetailsFormGroup">
            <div [ngClass]="{
              'container-content-xlarge': isXLarge(),
              'container-content-large': isLarge(),
              'container-content-medium': isMedium(),
              'container-content-small': isSmall(),
              'container-content-xsmall': isXSmall()
            }">
              <div class="container-column">
                <mat-form-field appearance="outline">
                  <mat-label>Title</mat-label>
                  <input matInput placeholder="Ex. My Promotion" formControlName="name" />
                  <mat-error *ngIf="
                      promotionDetailsFormGroup.get('name').hasError('required') &&
                      promotionDetailsFormGroup.get('name').touched
                      ">
                    <span>Title is required</span>
                  </mat-error>
                  <mat-error *ngIf="
                      promotionDetailsFormGroup.get('name').hasError('minlength') &&
                      promotionDetailsFormGroup.get('name').touched
                      ">
                    <span>Title should have a minimum length of {{ minNameLength }}</span>
                  </mat-error>
                  <mat-error *ngIf="
                      promotionDetailsFormGroup.get('name').hasError('maxlength') &&
                      promotionDetailsFormGroup.get('name').touched
                      ">
                    <span>Title can have a maximum length of {{ maxNameLength }}</span>
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>Description</mat-label>
                  <textarea matInput placeholder="Ex. My promotion description" formControlName="description"
                    style="height: 5em;"></textarea>
                  <mat-error *ngIf="
                      promotionDetailsFormGroup
                          .get('description')
                          .hasError('required') &&
                          promotionDetailsFormGroup.get('description').touched
                      ">
                    <span>Description is required</span>
                  </mat-error>
                  <mat-error *ngIf="
                        promotionDetailsFormGroup.get('description').hasError('minlength') &&
                        promotionDetailsFormGroup.get('description').touched
                      ">
                    <span>Description should have a minimum length of {{minDescriptionLength}}</span>
                  </mat-error>
                  <mat-error *ngIf="
                        promotionDetailsFormGroup.get('description').hasError('maxlength') &&
                        promotionDetailsFormGroup.get('description').touched
                      ">
                    <span>Description can have a maximum length of {{maxDescriptionLength}}</span>
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Start Date</mat-label>
                  <input class="ng-trim-ignore" matInput [mtxDatetimepicker]="startDatePicker"
                    formControlName="startDate" />
                  <mtx-datetimepicker-toggle matSuffix [for]="startDatePicker"></mtx-datetimepicker-toggle>
                  <mtx-datetimepicker #startDatePicker [twelvehour]="false" [timeInput]="false" [mode]="'auto'"
                    [type]="'datetime'" [timeInterval]="1"></mtx-datetimepicker>
                  <mat-error *ngIf="
                      promotionDetailsFormGroup
                          .get('startDate')
                          .hasError('required') &&
                          promotionDetailsFormGroup.get('startDate').touched
                      ">
                    <span>Start date is required</span>
                  </mat-error>
                  <mat-error *ngIf="
                        promotionDetailsFormGroup
                          .get('startDate')
                          .hasError('EndDateBeforeStartDate') &&
                        promotionDetailsFormGroup.get('startDate').touched
                      ">
                    <span>Start date must lie before end date</span>
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>End Date</mat-label>
                  <input class="ng-trim-ignore" matInput [mtxDatetimepicker]="endDatePicker"
                    formControlName="endDate" />
                  <mtx-datetimepicker-toggle matSuffix [for]="endDatePicker"></mtx-datetimepicker-toggle>
                  <mtx-datetimepicker #endDatePicker [twelvehour]="false" [timeInput]="false" [mode]="'auto'"
                    [type]="'datetime'" [timeInterval]="1"></mtx-datetimepicker>
                  <mat-error *ngIf="
                      promotionDetailsFormGroup
                          .get('endDate')
                          .hasError('required') &&
                          promotionDetailsFormGroup.get('endDate').touched
                      ">
                    <span>End date is required</span>
                  </mat-error>
                  <mat-error *ngIf="
                        promotionDetailsFormGroup
                          .get('endDate')
                          .hasError('EndDateBeforeStartDate') &&
                        promotionDetailsFormGroup.get('endDate').touched
                      ">
                    <span>End date must lie after start date</span>
                  </mat-error>
                </mat-form-field>

                <app-recurrence-field
                  (onRecurrencePatternChanged)="onRecurrencePatternChanged($event)"></app-recurrence-field>

              </div>
              <div class="container-column">
                <mat-form-field appearance="outline">
                  <mat-label>Voucher Reward Title</mat-label>
                  <input matInput placeholder="Ex. Free Cup of Coffee" formControlName="promotionRewardName" />
                  <mat-error *ngIf="
                        promotionDetailsFormGroup
                          .get('promotionRewardName')
                          .hasError('required') &&
                        promotionDetailsFormGroup.get('promotionRewardName').touched
                      ">
                    <span>Voucher Reward Title is required</span>
                  </mat-error>
                  <mat-error *ngIf="
                        promotionDetailsFormGroup.get('promotionRewardName').hasError('minlength') &&
                        promotionDetailsFormGroup.get('promotionRewardName').touched
                      ">
                    <span>Reward Title should have a minimum length of {{ minNameLength }}</span>
                  </mat-error>
                  <mat-error *ngIf="
                        promotionDetailsFormGroup.get('promotionRewardName').hasError('maxlength') &&
                        promotionDetailsFormGroup.get('promotionRewardName').touched
                      ">
                    <span>Voucher Reward Title can have a maximum length of {{maxNameLength}}</span>
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Voucher Reward Description</mat-label>
                  <textarea matInput placeholder="Ex. Thank you for supporting us."
                    formControlName="promotionRewardDescription" style="height: 5em;"></textarea>
                  <mat-error *ngIf="
                        promotionDetailsFormGroup
                          .get('promotionRewardDescription')
                          .hasError('required') &&
                        promotionDetailsFormGroup.get('promotionRewardDescription')
                          .touched
                      ">
                    <span>Voucher Reward description is required</span>
                  </mat-error>
                  <mat-error *ngIf="
                        promotionDetailsFormGroup.get('promotionRewardDescription').hasError('minlength') &&
                        promotionDetailsFormGroup.get('promotionRewardDescription').touched
                      ">
                    <span>Voucher Reward description should have a minimum length of {{ minDescriptionLength }}</span>
                  </mat-error>
                  <mat-error *ngIf="
                        promotionDetailsFormGroup.get('promotionRewardDescription').hasError('maxlength') &&
                        promotionDetailsFormGroup.get('promotionRewardDescription').touched
                      ">
                    <span>Voucher Reward description can have a maximum length of {{ maxDescriptionLength }}</span>
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>Voucher Valid From Date</mat-label>
                  <input class="ng-trim-ignore" matInput [mtxDatetimepicker]="voucherStartDatePicker"
                    formControlName="startDateVoucher" />
                  <mtx-datetimepicker-toggle matSuffix [for]="voucherStartDatePicker"></mtx-datetimepicker-toggle>
                  <mtx-datetimepicker #voucherStartDatePicker [twelvehour]="false" [timeInput]="false" [mode]="'auto'"
                    [type]="'datetime'" [timeInterval]="1"></mtx-datetimepicker>
                  <mat-error *ngIf="
                      promotionDetailsFormGroup
                          .get('startDateVoucher')
                          .hasError('required') &&
                          promotionDetailsFormGroup.get('startDateVoucher').touched
                      ">
                    <span>Voucher Valid From date is required</span>
                  </mat-error>
                  <mat-error *ngIf="
                        promotionDetailsFormGroup
                          .get('startDateVoucher')
                          .hasError('EndDateBeforeStartDate') &&
                        promotionDetailsFormGroup.get('startDateVoucher').touched
                      ">
                    <span>Voucher valid from date must lie before voucher valid until date</span>
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Voucher Valid Until Date</mat-label>
                  <input class="ng-trim-ignore" matInput [mtxDatetimepicker]="voucherEndDatePicker"
                    formControlName="endDateVoucher" />
                  <mtx-datetimepicker-toggle matSuffix [for]="voucherEndDatePicker"></mtx-datetimepicker-toggle>
                  <mtx-datetimepicker #voucherEndDatePicker [twelvehour]="false" [timeInput]="false" [mode]="'auto'"
                    [type]="'datetime'" [timeInterval]="1"></mtx-datetimepicker>
                  <mat-error *ngIf="
                      promotionDetailsFormGroup
                          .get('endDateVoucher')
                          .hasError('required') &&
                          promotionDetailsFormGroup.get('endDateVoucher').touched
                      ">
                    <span>Voucher Valid Until date is required</span>
                  </mat-error>
                  <mat-error *ngIf="
                        promotionDetailsFormGroup
                          .get('endDateVoucher')
                          .hasError('EndDateBeforeStartDate') &&
                        promotionDetailsFormGroup.get('endDateVoucher').touched
                      ">
                    <span>Voucher valid until date must lie after voucher valid from date</span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="container-actions">
              <button mat-flat-button matStepperNext color="primary" (click)="savePromotion()"
                [disabled]="!promotionDetailsFormGroup.valid">Next</button>
            </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="promotionLogoFormGroup" [editable]="false" [completed]="false">
          <ng-template matStepLabel>Upload your promotion image</ng-template>
          <form [formGroup]="promotionLogoFormGroup">
            <div class="container-content">
              <div class="container-logo">
                <ng-container *ngIf="(isImageSaved$| async); else elseTemplate">
                  <img [src]="(promotionLogoUrl$ | async)" class="logo" alt="promotion-logo" />
                </ng-container>
                <ng-template #elseTemplate>
                  <img [src]="placeholderUrl" class="logo" alt="promotion-logo" />
                </ng-template>
              </div>

            </div>
            <div class="container-logo-actions">
              <button mat-icon-button (click)="removeImage()" [disabled]="!(isImageSaved$| async)"
                matTooltip="Remove image">
                <mat-icon>delete</mat-icon>
              </button>
              <button type="button" mat-icon-button color="accent" (click)="fileInput.click()"
                matTooltip="Upload image">
                <mat-icon>upload</mat-icon>
              </button>
              <input hidden (change)="fileChangeEvent($event)" #fileInput type="file" id="file"
                accept=".jpg,.jpeg,.png" />

              <div class="container-logo-actions-next">
                <button mat-flat-button matStepperNext color="primary"
                  [disabled]="!this.promotionLogoFormGroup.valid">Next</button>
              </div>
            </div>
          </form>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Done</ng-template>
          <h2>
            What's next on the menu?
          </h2>
          <mat-list>
            <mat-list-item>
              <span matListItemIcon>
                <mat-icon color="primary">eco</mat-icon>
              </span>
              <div matListItemTitle class="list-item-title">Your promotion will be activated automatically on the given
                Start Date.</div>
            </mat-list-item>
            <mat-list-item>
              <span matListItemIcon>
                <mat-icon color="primary">eco</mat-icon>
              </span>
              <div matListItemTitle class="list-item-title">You can use the <mat-icon>edit</mat-icon> button to make
                changes to your promotion.</div>
            </mat-list-item>
            <mat-list-item>
              <span matListItemIcon>
                <mat-icon color="primary">eco</mat-icon>
              </span>
              <div matListItemTitle class="list-item-title">Check the dashboard to see how everything is performing.
              </div>
            </mat-list-item>
          </mat-list>
          <div class="container-actions">
            <button mat-flat-button color="primary" (click)="finishOnBoarding()">Done</button>
          </div>
        </mat-step>
      </mat-horizontal-stepper>
    </mat-card-content>
  </mat-card>
</div>