import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { catchError, map, Observable, switchMap, tap } from "rxjs";
import { PhotoModel } from "src/app/models/photo-model";
import { UploadPhotoModel } from "src/app/models/upload-photo-model";
import { PromotionLogoState } from "./promotion-logo.state";
import { PromotionLogoService } from "src/app/services/promotion-logo.service";

const initialState: PromotionLogoState = {
    loading: false,
    loaded: false,
    success: false,
    errorMessage: null,
    logo: null,
    uploadPhoto: null
}

@Injectable({
    providedIn: 'root'
})
export class PromotionLogoComponentStore extends ComponentStore<PromotionLogoState> {
    constructor(private readonly promotionLogoService: PromotionLogoService) {
        super(initialState);
    }

    readonly logo$: Observable<PhotoModel> = this.select(state => state.logo);
    readonly uploadPhoto$: Observable<UploadPhotoModel> = this.select(state => state.uploadPhoto);
    readonly loading$: Observable<boolean> = this.select(state => state.loading);
    readonly loaded$: Observable<boolean> = this.select(state => state.loaded);
    readonly success$: Observable<boolean> = this.select(state => state.success);
    readonly errorMessage$: Observable<any> = this.select(state => state.errorMessage);

    readonly viewModel$ = this.select(
        this.logo$,
        this.uploadPhoto$,
        this.loaded$,
        this.loading$,
        this.success$,
        this.errorMessage$,
        (logo, uploadPhoto, loaded, loading, success, errorMessage) => ({
            logo, uploadPhoto, loaded, loading, success, errorMessage
        })
    );

    readonly setInitial = this.updater((_: PromotionLogoState) => {
        return {
            ...initialState
        };
    });

    readonly setLoading = this.updater((state: PromotionLogoState) => {
        return {
            ...state,
            loading: true,
            loaded: false,
        };
    });

    readonly setLoaded = this.updater((state: PromotionLogoState) => {
        return {
            ...state,
            loading: false,
            loaded: true,
        };
    });

    readonly updatePromotionLogoState = this.updater((state: PromotionLogoState, value: {
        logo: PhotoModel
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: true,
            logo: value.logo,
            uploadPhoto : null
        };
    });

    readonly setError = this.updater((state: PromotionLogoState, value: {
        errorMessage: string
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: false,
            errorMessage: value.errorMessage,
        };
    });

    readonly getPromotionLogo = this.effect((params$: Observable<{ companyId: string, promotionId: number }>) => {
        this.setLoading();
        return params$.pipe(
            switchMap((params => this.promotionLogoService.getPromotionLogo(params.companyId, params.promotionId).pipe(
                tap({
                    next: (logo) => {
                        this.updatePromotionLogoState({ logo: logo });
                        this.setLoaded();
                    },
                    error: (e) => {
                        throw e;
                    }
                }),
                catchError(async (error) => {
                    this.setError({ errorMessage: error })
                    this.setLoaded();
                    this.setInitial();
                })
            ))
            )
        );
    });

    readonly uploadPromotionLogo = this.effect((params$: Observable<{ companyId: string, promotionId: number, uploadPhoto: UploadPhotoModel }>) => {
        this.setLoading();
        return params$.pipe(
            switchMap((params => this.promotionLogoService.uploadPromotionLogo(params.companyId, params.promotionId, params.uploadPhoto).pipe(
                tap({
                    next: (logo) => {
                        this.updatePromotionLogoState({ logo: logo });
                        this.setLoaded();
                    },
                    error: (e) => {
                        throw e;
                    }
                }),
                catchError(async (error) => {
                    this.setError({ errorMessage: error })
                    this.setLoaded();
                    this.setInitial();
                })
            ))
            )
        );
    });

    readonly deletePromotionLogo = this.effect((params$: Observable<{ companyId: string, promotionId: number }>) => {
        this.setLoading();
        return params$.pipe(
            switchMap((params => this.promotionLogoService.deletePromotionLogo(params.companyId, params.promotionId).pipe(
                map(() => {
                    this.updatePromotionLogoState({ logo: null });
                    this.setLoaded();
                }),
                catchError(async (error) => {
                    this.setError({ errorMessage: error })
                    this.setLoaded();
                    this.setInitial();
                })
            ))
            )
        );
    });

    readonly reset = this.effect((params$ : Observable<{}>) => {
        return params$.pipe(
            map(() => this.setInitial())
        );
    });
}