<div class="container">
  <div [ngClass]="{
    'container-content-xlarge': isXLarge(),
    'container-content-large': isLarge(),
    'container-content-medium': isMedium(),
    'container-content-small': isSmall(),
    'container-content-xsmall': isXSmall()
  }">
    <div [ngClass]="{
    'container-campaign-xlarge': isXLarge(),
    'container-campaign-large': isLarge(),
    'container-campaign-medium': isMedium(),
    'container-campaign-small': isSmall(),
    'container-campaign-xsmall': isXSmall()
  }">
      <div *ngIf="!editMode; else showEdit">
        <app-campaign [campaign]="(viewModel$ | async)?.campaign" (onDeletePressed)="onDelete($event)"
          (onActivatePressed)="onActivate($event)" (onEditPressed)="onEdit($event)"></app-campaign>
      </div>
    </div>
    <div class="container-campaign-logo">
      <app-campaign-logo [campaign]="(viewModel$ | async)?.campaign"></app-campaign-logo>
    </div>
  </div>
</div>

<ng-template #showEdit>
  <app-campaign-edit [campaign]="(viewModel$ | async)?.campaign" (onCancelPressed)="onEditCancelPressed($event)"
    (onUpdateSuccess)="onUpdateSuccess($event)"></app-campaign-edit>
</ng-template>