<div [ngClass]="{
  'container-xlarge': isXLarge(),
  'container-large': isLarge(),
  'container-medium': isMedium(),
  'container-small': isSmall(),
  'container-xsmall': isXSmall()
}">
    <mat-card appearance="outlined" class="mat-component-background mat-elevation-z2">
      <mat-card-header>
        <mat-card-title>Planet VG Store User Account Details</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="container-content">
          <div class="container-column">
            <mat-form-field appearance="outline">
              <mat-label>Username</mat-label>
              <input matInput readonly value="{{ storeUser.username }}" />
            </mat-form-field>
          </div>
         </div>
      </mat-card-content>
      <mat-card-actions class="container-actions">
        <button mat-icon-button color="secondary" [cdkCopyToClipboard]="storeUser.username" (click)="onCopyClick()" matTooltip="Copy store username to clipboard">
          <mat-icon>content_copy</mat-icon>
      </button>
          <button 
            mat-icon-button 
            matTooltip="Generate new Planet VG Store User password"
            (click)="generateNewPassword()">
            <mat-icon>lock_reset</mat-icon>
          </button>
      </mat-card-actions>
    </mat-card>
  </div>
  