import { Component, Input } from '@angular/core';
import { ResponsiveBaseComponent } from '../responsive-base/responsive-base.component';
import { TestimonialViewModel } from 'src/app/models/viewmodels/testimonial-view-model';

@Component({
  selector: 'app-testimonial-card',
  templateUrl: './testimonial-card.component.html',
  styleUrl: './testimonial-card.component.scss'
})
export class TestimonialCardComponent extends ResponsiveBaseComponent {
  @Input()
  public testimonial : TestimonialViewModel;
}
