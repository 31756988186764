import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { UpdatePromotionModel } from 'src/app/models/update-promotion-model';
import { MaximumActivePromotionsExceededException } from '../../exceptions/maximum-active-promotions-exceeded-exception';
import { ValidationException } from '../../exceptions/validation-exception';
import { EndDateAfterStartDate } from '../../validators/start-end-date.validator';
import { UpdatePromotionComponentStore } from 'src/app/stores/components/promotion-update/update-promotion.component-store';
import { PromotionModel } from 'src/app/models/promotion-model';
import { MatDialogRef } from '@angular/material/dialog';
import { SpinnerDialogComponent } from '../dialogs/spinner-dialog/spinner-dialog.component';
import { SpinnerService } from 'src/app/services/spinner.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-promotion-edit',
  templateUrl: './promotion-edit.component.html',
  styleUrls: ['./promotion-edit.component.scss']
})
export class PromotionEditComponent {
  @Input()
  public promotionDetailsFormGroup: UntypedFormGroup;

  @Input()
  public promotion: PromotionModel;

  @Output()
  public onCancelPressed = new EventEmitter();

  @Output()
  public onSavePressed = new EventEmitter();

  @Output()
  public onUpdateSuccess = new EventEmitter();

  public minNameLength: number = 3;
  public maxNameLength: number = 32;
  public minDescriptionLength: number = 3;
  public maxDescriptionLength: number = 255;

  private readonly ngUnsubscribeState = new Subject<void>();
  private loadingSpinnerDialogRef: MatDialogRef<SpinnerDialogComponent>;
  private _isXLarge: boolean;
  private _isLarge: boolean
  private _isMedium: boolean;
  private _isSmall: boolean;
  private _isXSmall: boolean;

  private companyId: string;

  constructor(private formBuilder: UntypedFormBuilder,
    private updatePromotionComponentStore: UpdatePromotionComponentStore,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private spinnerService: SpinnerService,
    private readonly responsive: BreakpointObserver,
  ) { }

  public ngOnInit(): void {
    this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge]).subscribe(
      result => {
        const breakpoints = result.breakpoints;

        this._isXLarge = false;
        this._isLarge = false;
        this._isMedium = false;
        this._isSmall = false;
        this._isXSmall = false;

        if (breakpoints[Breakpoints.XSmall]) {
          this._isXSmall = true;
        }
        else if (breakpoints[Breakpoints.Small]) {
          this._isSmall = true;
        }
        else if (breakpoints[Breakpoints.Medium]) {
          this._isMedium = true;
        }
        else if (breakpoints[Breakpoints.Large]) {
          this._isLarge = true;
        }
        else {
          this._isXLarge = true;
        }
      }
    );

    this.route.params.subscribe(params => {
      this.companyId = params.companyId;
    });

    let startDate = new Date();
    let endDate = new Date();
    startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
    endDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
    endDate.setDate(endDate.getDate() + 3);


    let startDateVoucher = new Date();
    let endDateVoucher = new Date();
    startDateVoucher = new Date(startDateVoucher.getFullYear(), startDateVoucher.getMonth(), startDateVoucher.getDate());
    startDateVoucher.setDate(endDate.getDate() + 1)
    endDateVoucher = new Date(endDateVoucher.getFullYear(), endDateVoucher.getMonth(), endDateVoucher.getDate());
    endDateVoucher.setDate(startDateVoucher.getDate() + 1);

    this.promotionDetailsFormGroup = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(this.minNameLength), Validators.maxLength(this.maxNameLength)]],
      description: ['', [Validators.required, Validators.minLength(this.minDescriptionLength), Validators.maxLength(this.maxDescriptionLength)]],
      startDate: [startDate, Validators.required],
      endDate: [endDate, Validators.required],
      startDateVoucher: [startDateVoucher, Validators.required],
      endDateVoucher: [endDateVoucher, Validators.required],
      promotionRewardName: ['', [Validators.required, Validators.minLength(this.minNameLength), Validators.maxLength(this.maxNameLength)]],
      promotionRewardDescription: ['', [Validators.required, Validators.minLength(this.minDescriptionLength), Validators.maxLength(this.maxDescriptionLength)]],
      recurrencePattern: [''],
    }, {
      validator: [EndDateAfterStartDate('startDate', 'endDate'), EndDateAfterStartDate('startDateVoucher', 'endDateVoucher')],
    });

    if (this.promotion) {
      this.promotionDetailsFormGroup.patchValue({ 'name': this.promotion.name });
      this.promotionDetailsFormGroup.patchValue({ 'description': this.promotion.description });
      this.promotionDetailsFormGroup.patchValue({ 'startDate': new Date(this.promotion.startDate) });
      this.promotionDetailsFormGroup.patchValue({ 'endDate': new Date(this.promotion.endDate) });
      this.promotionDetailsFormGroup.patchValue({ 'startDateVoucher': new Date(this.promotion.startDateVoucher) });
      this.promotionDetailsFormGroup.patchValue({ 'endDateVoucher': new Date(this.promotion.endDateVoucher) });
      this.promotionDetailsFormGroup.patchValue({ 'promotionRewardName': this.promotion.promotionReward.name });
      this.promotionDetailsFormGroup.patchValue({ 'promotionRewardDescription': this.promotion.promotionReward.description });
      this.promotionDetailsFormGroup.patchValue({ 'recurrencePattern': this.promotion.recurrencePattern });
    }

    this.updatePromotionComponentStore.promotion$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((result) => {
      this.hideSpinner(true);

      if (result) {
        this.snackBar.open("Promotion updated successfully.", "Dismiss");
        this.router.navigate(['/companies/' + result.company.id + '/promotions/' + result.id]);

        this.onUpdateSuccess.emit(null);
      }
    });

    this.updatePromotionComponentStore.errorMessage$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((errorMessage) => {
      this.hideSpinner(true);

      if (errorMessage !== null && errorMessage !== undefined) {
        if (errorMessage instanceof ValidationException) {
          const validationViolationMessage = errorMessage.violations.map(v => v.field + ' ' + v.message).join("\n");
          this.snackBar.open(validationViolationMessage, "Dismiss");
        }
        else if (errorMessage instanceof MaximumActivePromotionsExceededException) {
          this.snackBar.open("Can't create a new promotion. You have already reached the maximum.", "Dismiss");
        }
        else {
          this.snackBar.open(errorMessage, "Dismiss");
        }
      }
    });
  }

  public save() : void {
    this.promotionDetailsFormGroup.markAllAsTouched();

    if (this.promotionDetailsFormGroup.valid) {
      this.showSpinner();

      const updatePromotion = new UpdatePromotionModel();
      updatePromotion.name = this.promotionDetailsFormGroup.value.name;
      updatePromotion.description = this.promotionDetailsFormGroup.value.description;
      updatePromotion.startDate = new Date(this.promotionDetailsFormGroup.value.startDate);
      updatePromotion.endDate = new Date(this.promotionDetailsFormGroup.value.endDate);
      updatePromotion.startDateVoucher = new Date(this.promotionDetailsFormGroup.value.startDateVoucher);
      updatePromotion.endDateVoucher = new Date(this.promotionDetailsFormGroup.value.endDateVoucher);

      updatePromotion.promotionRewardName = this.promotionDetailsFormGroup.value.promotionRewardName;
      updatePromotion.promotionRewardDescription = this.promotionDetailsFormGroup.value.promotionRewardDescription;
      updatePromotion.recurrencePattern = this.promotionDetailsFormGroup.value.recurrencePattern;

      updatePromotion.promotionStatus = this.promotion.promotionStatus;

      this.updatePromotionComponentStore.updatePromotion({ companyId: this.companyId, promotionId: this.promotion.id, updatePromotion: updatePromotion });
    }
  }

  public onRecurrencePatternChanged($event): void {
    this.promotionDetailsFormGroup.patchValue({ recurrencePattern: $event });
  }

  public cancel() : void {
    this.onCancelPressed.emit(null);
  }

  public isXLarge(): boolean {
    return this._isXLarge;
  }

  public isLarge(): boolean {
    return this._isLarge;
  }

  public isMedium(): boolean {
    return this._isMedium;
  }

  public isSmall(): boolean {
    return this._isSmall;
  }

  public isXSmall(): boolean {
    return this._isXSmall;
  }

  private showSpinner() : void {
    this.loadingSpinnerDialogRef = this.spinnerService.show();
  }

  private hideSpinner(loaded: boolean) : void {
    if (loaded && this.loadingSpinnerDialogRef !== null) {
      this.spinnerService.hide(this.loadingSpinnerDialogRef);
      this.loadingSpinnerDialogRef = null;
    }
  }
}
