import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface DialogData {
  title: string;
  message: string;
  data: string;
  confirm: string;
  cancel: string;
}

@Component({
  selector: 'app-share-link-dialog',
  templateUrl: './share-link-dialog.component.html',
  styleUrl: './share-link-dialog.component.scss'
})
export class ShareLinkDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ShareLinkDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private readonly snackbar : MatSnackBar,
  ) {
    if (data.confirm === null || data.confirm === undefined) {
      data.confirm = "Ok";
    }
  }

  public onConfirmClick(): void {
    this.dialogRef.close(true);
  }

  public onCopyClick(): void {
    this.snackbar.open("Copied link to clipboard", "Dismiss", { duration: 1000 });
    this.dialogRef.close(true);
  }
}
