<div class="container">
  <div *ngIf="unpaidInvoice$ | async">
    <app-unpaid-invoice-banner [unpaidInvoice]="unpaidInvoice$ | async"></app-unpaid-invoice-banner>
  </div>

  <div *ngIf="showAdd; else showShop">
    <div [ngClass]="{
      'container-content-xlarge': isXLarge(),
      'container-content-large': isLarge(),
      'container-content-medium': isMedium(),
      'container-content-small': isSmall(),
      'container-content-xsmall': isXSmall()
    }">
      <div class="container-content-add">
        <button mat-raised-button (click)="create()" color="primary">
          <mat-icon>post_add</mat-icon>
          New
        </button>
      </div>
      <div class="container-content-grid">
        <table description="Overview of promotions" mat-table [dataSource]="(viewModel$ | async)?.promotions"
          class="mat-component-background mat-elevation-z2">
          <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

          <!-- Id Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>Id</th>
            <td mat-cell *matCellDef="let element">{{ element.id }}</td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Title</th>
            <td mat-cell *matCellDef="let element">{{ element.name }}</td>
          </ng-container>

          <!-- Start Date Column -->
          <ng-container matColumnDef="startDate" *ngIf="!isSmall() && !isXSmall()">
            <th mat-header-cell *matHeaderCellDef>Start Date</th>
            <td mat-cell *matCellDef="let element">
              {{ element.startDate | date : "EEEE, MMMM d, y, HH:mm:ss" }}
            </td>
          </ng-container>

          <!-- End Date Column -->
          <ng-container matColumnDef="endDate" *ngIf="!isSmall() && !isXSmall()">
            <th mat-header-cell *matHeaderCellDef>End Date</th>
            <td mat-cell *matCellDef="let element">
              {{ element.endDate | date : "EEEE, MMMM d, y, HH:mm:ss" }}
            </td>
          </ng-container>

          <!-- Recurrence Pattern Column -->
          <ng-container matColumnDef="recurrencePattern" *ngIf="!isSmall() && !isXSmall()">
            <th mat-header-cell *matHeaderCellDef>Recurrence</th>
            <td mat-cell *matCellDef="let element">
              {{ getRecurrenceDisplayString(element.recurrencePattern) }}
            </td>
          </ng-container>

          <!-- Voucher Start Date Column -->
          <ng-container matColumnDef="voucherStartDate" *ngIf="!isSmall() && !isXSmall()">
            <th mat-header-cell *matHeaderCellDef>Voucher Start Date</th>
            <td mat-cell *matCellDef="let element">
              {{ element.startDateVoucher | date : "EEEE, MMMM d, y, HH:mm:ss" }}
            </td>
          </ng-container>

          <!-- Voucher End Date Column -->
          <ng-container matColumnDef="voucherEndDate" *ngIf="!isSmall() && !isXSmall()">
            <th mat-header-cell *matHeaderCellDef>Voucher End Date</th>
            <td mat-cell *matCellDef="let element">
              {{ element.endDateVoucher | date : "EEEE, MMMM d, y, HH:mm:ss" }}
            </td>
          </ng-container>

          <!-- Promotion Status Column -->
          <ng-container matColumnDef="promotionStatus">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let element">
              {{ element.promotionStatus }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
          <tr mat-row *matRowDef="let row; columns: getDisplayedColumns()"
            [routerLink]="['/companies', row.company.id, 'promotions', row.id]"></tr>
        </table>
        <div class="container-paginator">
          <mat-paginator [length]="(viewModel$ | async)?.totalElements" [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions" (page)="onPageEvent($event)" [hidePageSize]="true"
            class="mat-component-background mat-elevation-z2">
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #showShop>
  <app-buy-subscription-plan-banner></app-buy-subscription-plan-banner>
</ng-template>