<div [ngClass]="{
  'container-xlarge': isXLarge(),
  'container-large': isLarge(),
  'container-medium': isMedium(),
  'container-small': isSmall(),
  'container-xsmall': isXSmall()
}">
  <mat-card appearance="outlined" class="mat-component-background mat-elevation-z2">
    <mat-card-header>
      <mat-card-title> Stamp Card Details </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div [ngClass]="{
        'container-content-xlarge': isXLarge(),
        'container-content-large': isLarge(),
        'container-content-medium': isMedium(),
        'container-content-small': isSmall(),
        'container-content-xsmall': isXSmall()
      }">
        <div class="container-column">
          <mat-form-field appearance="outline">
            <mat-label>Title</mat-label>
            <input matInput readonly value="{{ campaign?.name }}" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Description</mat-label>
            <textarea matInput readonly cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"
              style="height: 5em;" value="{{ campaign?.description }}"></textarea>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Start Date</mat-label>
            <input matInput readonly value="{{ campaign?.startDate | date : 'EEEE, MMMM d, y, HH:mm' }}" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>End Date</mat-label>
            <input matInput readonly value="{{ campaign?.endDate | date : 'EEEE, MMMM d, y, HH:mm' }}" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Recurrence</mat-label>
            <input matInput readonly value="{{ recurrenceDisplayString }}" />
          </mat-form-field>
        </div>
        <div class="container-column">
          <mat-form-field appearance="outline">
            <mat-label>Stamps Objective</mat-label>
            <input matInput readonly value="{{ campaign?.stampsObjective }}" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Voucher Reward Title</mat-label>
            <input matInput readonly value="{{ campaign?.campaignReward.name }}" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Voucher Reward Description</mat-label>
            <textarea matInput readonly cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"
              style="height: 5em;" value="{{ campaign?.campaignReward.description }}"></textarea>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Voucher Validity Period</mat-label>
            <input matInput readonly value="{{ campaign?.toVoucherDurationDisplayString() }}" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Status</mat-label>
            <input matInput readonly value="{{ campaign?.campaignStatus }}" />
          </mat-form-field>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions class="container-actions">
      <div *ngIf="canShowShareAction()">
        <a mat-icon-button target="_blank" rel="noopener" href="{{ getCampaignLink() }}" matTooltip="View on website">
          <mat-icon>open_in_new</mat-icon>
        </a>
        <button mat-icon-button (click)="share()" matTooltip="Share this on social media">
          <mat-icon>share</mat-icon>
        </button>
      </div>
      <div *ngIf="canShowEditAction()">
        <button mat-icon-button (click)="edit()" matTooltip="Edit">
          <mat-icon>edit</mat-icon>
        </button>
      </div>
      <div *ngIf="canShowArchiveAction()">
        <button mat-icon-button (click)="delete()" matTooltip="Archive">
          <mat-icon>archive</mat-icon>
        </button>
      </div>
      <div *ngIf="canShowActivateAction()">
        <button mat-icon-button (click)="activate()" matTooltip="Activate">
          <mat-icon>play_circle</mat-icon>
        </button>
      </div>
    </mat-card-actions>
  </mat-card>
</div>