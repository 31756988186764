<div class="container">
  <mat-card appearance="outlined" class="mat-component-background mat-elevation-z2">
    <mat-card-header>
      <mat-card-title> Create New Stamp Card </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-horizontal-stepper [linear]="true" #stepper class="mat-component-background">
        <mat-step [stepControl]="campaignDetailsFormGroup" [editable]="false" [completed]="false">
          <ng-template matStepLabel>Fill out your stamp card details</ng-template>
          <form [formGroup]="campaignDetailsFormGroup">
            <div [ngClass]="{
              'container-content-xlarge': isXLarge(),
              'container-content-large': isLarge(),
              'container-content-medium': isMedium(),
              'container-content-small': isSmall(),
              'container-content-xsmall': isXSmall()
            }">
              <div class="container-column">
                <mat-form-field appearance="outline">
                  <mat-label>Title</mat-label>
                  <input matInput placeholder="Ex. My stamp card" formControlName="name" />
                  <mat-error *ngIf="
                    campaignDetailsFormGroup.get('name').hasError('required') &&
                    campaignDetailsFormGroup.get('name').touched
                  ">
                    <span>Title is required</span>
                  </mat-error>
                  <mat-error *ngIf="
                    campaignDetailsFormGroup.get('name').hasError('minlength') &&
                    campaignDetailsFormGroup.get('name').touched
                  ">
                    <span>Title should have a minimum length of
                      {{ minNameLength }}</span>
                  </mat-error>
                  <mat-error *ngIf="
                    campaignDetailsFormGroup.get('name').hasError('maxlength') &&
                    campaignDetailsFormGroup.get('name').touched
                  ">
                    <span>Title can have a maximum length of
                      {{ maxNameLength }}</span>
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>Description</mat-label>
                  <textarea matInput placeholder="Ex. My stamp card description" formControlName="description"
                    style="height: 5em;"></textarea>
                  <mat-error *ngIf="
                    campaignDetailsFormGroup
                      .get('description')
                      .hasError('required') &&
                    campaignDetailsFormGroup.get('description').touched
                  ">
                    <span>Description is required</span>
                  </mat-error>
                  <mat-error *ngIf="
                    campaignDetailsFormGroup
                      .get('description')
                      .hasError('minlength') &&
                    campaignDetailsFormGroup.get('description').touched
                  ">
                    <span>Description should have a minimum length of
                      {{ minDescriptionLength }}</span>
                  </mat-error>
                  <mat-error *ngIf="
                    campaignDetailsFormGroup
                      .get('description')
                      .hasError('maxlength') &&
                    campaignDetailsFormGroup.get('description').touched
                  ">
                    <span>Description can have a maximum length of
                      {{ maxDescriptionLength }}</span>
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>Start Date</mat-label>
                  <input class="ng-trim-ignore" matInput [mtxDatetimepicker]="startDatePicker"
                    formControlName="startDate" />
                  <mtx-datetimepicker-toggle matSuffix [for]="startDatePicker"></mtx-datetimepicker-toggle>
                  <mtx-datetimepicker #startDatePicker [twelvehour]="false" [timeInput]="false" [mode]="'auto'"
                    [type]="'datetime'" [timeInterval]="1"></mtx-datetimepicker>
                  <mat-error *ngIf="
                    campaignDetailsFormGroup
                      .get('startDate')
                      .hasError('required') &&
                    campaignDetailsFormGroup.get('startDate').touched
                  ">
                    <span>Start date is required</span>
                  </mat-error>
                  <mat-error *ngIf="
                    campaignDetailsFormGroup
                      .get('startDate')
                      .hasError('EndDateBeforeStartDate') &&
                    campaignDetailsFormGroup.get('startDate').touched
                  ">
                    <span>Start date must lie before end date</span>
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>End Date</mat-label>
                  <input class="ng-trim-ignore" matInput [mtxDatetimepicker]="endDatePicker"
                    formControlName="endDate" />
                  <mtx-datetimepicker-toggle matSuffix [for]="endDatePicker"></mtx-datetimepicker-toggle>
                  <mtx-datetimepicker #endDatePicker [twelvehour]="false" [timeInput]="false" [mode]="'auto'"
                    [type]="'datetime'" [timeInterval]="1"></mtx-datetimepicker>
                  <mat-error *ngIf="
                    campaignDetailsFormGroup.get('endDate').hasError('required') &&
                    campaignDetailsFormGroup.get('endDate').touched
                  ">
                    <span>End date is required</span>
                  </mat-error>
                  <mat-error *ngIf="
                    campaignDetailsFormGroup
                      .get('endDate')
                      .hasError('EndDateBeforeStartDate') &&
                    campaignDetailsFormGroup.get('endDate').touched
                  ">
                    <span>End date must lie after start date</span>
                  </mat-error>
                </mat-form-field>

                <app-recurrence-field
                  (onRecurrencePatternChanged)="onRecurrencePatternChanged($event)"></app-recurrence-field>

              </div>
              <div class="container-column">
                <mat-form-field appearance="outline">
                  <mat-label>Stamps Objective</mat-label>
                  <div class="container-stamps">
                    <mat-slider thumbLabel discrete aria-label="units" [max]="30" [min]="1" [step]="1" #ngSlider><input
                        matSliderThumb [value]="
                        campaignDetailsFormGroup.get('stampsObjective').value
                      " (change)="
                        onStampsSliderChanged({
                          source: ngSliderThumb,
                          parent: ngSlider,
                          value: ngSliderThumb.value
                        })
                      " #ngSliderThumb="matSliderThumb" />
                    </mat-slider>
                    <input type="stampsObjective" matInput readonly formControlName="stampsObjective" />
                  </div>

                  <mat-error *ngIf="
                    campaignDetailsFormGroup
                      .get('stampsObjective')
                      .hasError('required') &&
                    campaignDetailsFormGroup.get('stampsObjective').touched
                  ">
                    <span>Stamps Objective is required</span>
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>Voucher Reward Title</mat-label>
                  <input matInput placeholder="Ex. Free Cup of Coffee" formControlName="campaignRewardName" />
                  <mat-error *ngIf="
                    campaignDetailsFormGroup
                      .get('campaignRewardName')
                      .hasError('required') &&
                    campaignDetailsFormGroup.get('campaignRewardName').touched
                  ">
                    <span>Voucher Reward Title is required</span>
                  </mat-error>
                  <mat-error *ngIf="
                    campaignDetailsFormGroup
                      .get('campaignRewardName')
                      .hasError('minlength') &&
                    campaignDetailsFormGroup.get('campaignRewardName').touched
                  ">
                    <span>Voucher Reward Title should have a minimum length of
                      {{ minNameLength }}</span>
                  </mat-error>
                  <mat-error *ngIf="
                    campaignDetailsFormGroup
                      .get('campaignRewardName')
                      .hasError('maxlength') &&
                    campaignDetailsFormGroup.get('campaignRewardName').touched
                  ">
                    <span>Voucher Reward Title can have a maximum length of
                      {{ maxNameLength }}</span>
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Voucher Reward Description</mat-label>
                  <textarea matInput placeholder="Ex. Thank you for supporting us."
                    formControlName="campaignRewardDescription" style="height: 5em;"></textarea>
                  <mat-error *ngIf="
                    campaignDetailsFormGroup
                      .get('campaignRewardDescription')
                      .hasError('required') &&
                    campaignDetailsFormGroup.get('campaignRewardDescription')
                      .touched
                  ">
                    <span>Voucher Reward description is required</span>
                  </mat-error>
                  <mat-error *ngIf="
                    campaignDetailsFormGroup
                      .get('campaignRewardDescription')
                      .hasError('minlength') &&
                    campaignDetailsFormGroup.get('campaignRewardDescription')
                      .touched
                  ">
                    <span>Voucher Reward description should have a minimum length of
                      {{ minDescriptionLength }}</span>
                  </mat-error>
                  <mat-error *ngIf="
                    campaignDetailsFormGroup
                      .get('campaignRewardDescription')
                      .hasError('maxlength') &&
                    campaignDetailsFormGroup.get('campaignRewardDescription')
                      .touched
                  ">
                    <span>Voucher Reward description can have a maximum length of
                      {{ maxDescriptionLength }}</span>
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Voucher Expiration</mat-label>
                  <mat-select formControlName="voucherDuration" panelClass="mat-app-background">
                    <mat-option *ngFor="let voucherDuration of voucherDurations" [value]="voucherDuration.value">
                      {{ voucherDuration.viewValue }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="
                    campaignDetailsFormGroup
                      .get('voucherDuration')
                      .hasError('required') &&
                    campaignDetailsFormGroup.get('voucherDuration').touched
                  ">
                    <span>Voucher duration is required</span>
                  </mat-error>
                </mat-form-field>
              </div>

            </div>
            <div class="container-actions">
              <button mat-flat-button matStepperNext color="primary" (click)="saveCampaign()"
                [disabled]="!campaignDetailsFormGroup.valid">Next</button>
            </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="campaignLogoFormGroup" [editable]="false" [completed]="false">
          <ng-template matStepLabel>Upload your stamp card image</ng-template>
          <form [formGroup]="campaignLogoFormGroup">
            <div class="container-content">
              <div class="container-logo">
                <ng-container *ngIf="(isImageSaved$| async); else elseTemplate">
                  <img [src]="(campaignLogoUrl$ | async)" class="logo" alt="stamp-card-logo" />
                </ng-container>
                <ng-template #elseTemplate>
                  <img [src]="placeholderUrl" class="logo" alt="stamp-card-logo" />
                </ng-template>
              </div>

            </div>
            <div class="container-logo-actions">
              <button mat-icon-button (click)="removeImage()" [disabled]="!(isImageSaved$| async)"
                matTooltip="Remove image">
                <mat-icon>delete</mat-icon>
              </button>
              <button type="button" mat-icon-button color="accent" (click)="fileInput.click()"
                matTooltip="Upload image">
                <mat-icon>upload</mat-icon>
              </button>
              <input hidden (change)="fileChangeEvent($event)" #fileInput type="file" id="file"
                accept=".jpg,.jpeg,.png" />

              <div class="container-logo-actions-next">
                <button mat-flat-button matStepperNext color="primary"
                  [disabled]="!this.campaignLogoFormGroup.valid">Next</button>
              </div>
            </div>
          </form>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Done</ng-template>
          <h2>
            What's next on the menu?
          </h2>
          <mat-list>
            <mat-list-item>
              <span matListItemIcon>
                <mat-icon color="primary">eco</mat-icon>
              </span>
              <div matListItemTitle class="list-item-title">Your stamp card will be activated automatically on the given
                Start Date.</div>
            </mat-list-item>
            <mat-list-item>
              <span matListItemIcon>
                <mat-icon color="primary">eco</mat-icon>
              </span>
              <div matListItemTitle class="list-item-title">You can use the <mat-icon>edit</mat-icon> button to make
                changes to your stamp card.</div>
            </mat-list-item>
            <mat-list-item>
              <span matListItemIcon>
                <mat-icon color="primary">eco</mat-icon>
              </span>
              <div matListItemTitle class="list-item-title">Check the dashboard to see how everything is performing.
              </div>
            </mat-list-item>
          </mat-list>
          <div class="container-actions">
            <button mat-flat-button color="primary" (click)="finishOnBoarding()">Done</button>
          </div>
        </mat-step>
      </mat-horizontal-stepper>
    </mat-card-content>
  </mat-card>
</div>