<div *ngIf="isXLarge() || isLarge(); else mediumToolbar">
  <mat-toolbar class="mat-component-background mat-elevation-z2">
    <mat-toolbar-row>
      <div *ngIf="
          (isAuthenticated$ | async)?.isAuthenticated && hasBusinessAccount
        ">
        <span>
          <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()" color="primary">
            <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
          </button>
        </span>
      </div>
      <div *ngIf="
          !(isAuthenticated$ | async)?.isAuthenticated;
          else dashboardHomeButton
        ">
        <span>
          <button mat-button routerLink="/home" [ngClass]="{
              'banner-button-xlarge': isXLarge(),
              'banner-button-large': isLarge(),
              'banner-button-medium': isMedium(),
              'banner-button-small': isSmall(),
              'banner-button-xsmall': isXSmall()
            }">
            <img src="/assets/img/planetvg-partner-banner.png" alt="planetvg-banner" />
          </button>
        </span>
      </div>
      <div [ngClass]="{
          'container-buttons-large': isXLarge() || isLarge(),
          'container-buttons-medium': isMedium()
        }">
        <ul [ngClass]="{
            'toolbar-row-buttons-large': isXLarge() || isLarge(),
            'toolbar-row-buttons-medium': isMedium()
          }">
          <li>
            <div *ngIf="
                !(isAuthenticated$ | async)?.isAuthenticated ||
                ((isAuthenticated$ | async)?.isAuthenticated &&
                  hasNoActiveSubscriptionPlanOrder)
              ">
              <span><a href="#" mat-button color="primary" routerLink="/about" class="topmenu-item">About</a></span>
            </div>
          </li>
          <li>
            <div *ngIf="
                !(isAuthenticated$ | async)?.isAuthenticated ||
                ((isAuthenticated$ | async)?.isAuthenticated &&
                  hasNoActiveSubscriptionPlanOrder)
              ">
              <span><a href="#" mat-button color="primary" routerLink="/how-it-works" class="topmenu-item">How it
                  works</a></span>
            </div>
          </li>
          <li>
            <div *ngIf="
                !(isAuthenticated$ | async)?.isAuthenticated ||
                ((isAuthenticated$ | async)?.isAuthenticated &&
                  hasNoActiveSubscriptionPlanOrder)
              ">
              <span><button mat-button color="primary" [matMenuTriggerFor]="resourcesMenu">
                  Resources
                </button>
              </span>
            </div>
          </li>
          <li>
            <div *ngIf="
                !(isAuthenticated$ | async)?.isAuthenticated ||
                ((isAuthenticated$ | async)?.isAuthenticated &&
                  hasNoActiveSubscriptionPlanOrder)
              ">
              <span><a href="#" mat-button color="primary" routerLink="/pricing" class="topmenu-item">Pricing</a></span>
            </div>
          </li>
          <li>
            <div *ngIf="!(isAuthenticated$ | async)?.isAuthenticated">
              <button mat-raised-button color="primary" [routerLink]="['/register']">
                <mat-icon>app_registration</mat-icon>
                Sign Up
              </button>
            </div>
          </li>
          <li>
            <div *ngIf="(isAuthenticated$ | async)?.isAuthenticated; else noAuth">
              <button mat-mini-fab color="primary" [matMenuTriggerFor]="menu">
                <mat-icon>person_outline</mat-icon>
              </button>
              <mat-menu #menu="" matMenu class="mat-component-background">
                <a href="{{ accountPortalUrl }}" target="_blank" rel="noopener" mat-menu-item><mat-icon><span
                      class="material-icons">person</span></mat-icon>Profile<mat-icon>open_in_new</mat-icon></a>
                <div *ngIf="
                    (isAuthenticated$ | async)?.isAuthenticated &&
                    hasBusinessAccount
                  ">
                  <button mat-menu-item [routerLink]="['/account']" routerLinkActive="list-item-active">
                    <mat-icon>manage_accounts</mat-icon>
                    <span>Account</span>
                  </button>
                  <button mat-menu-item [routerLink]="['/billing']" routerLinkActive="list-item-active">
                    <mat-icon>credit_card</mat-icon>
                    <span>Billing</span>
                  </button>
                </div>
                <button mat-menu-item [routerLink]="['/help-center']" routerLinkActive="list-item-active">
                  <mat-icon>help</mat-icon>
                  <span>Help Center</span>
                </button>
                <mat-divider></mat-divider>
                <button mat-menu-item (click)="logout()">
                  <mat-icon><span class="material-icons">logout</span></mat-icon>
                  Sign Out
                </button>
              </mat-menu>
            </div>
          </li>
        </ul>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

<mat-sidenav-container #sidenavContainer>
  <mat-sidenav #drawer mode="side" [opened]="
      (isAuthenticated$ | async)?.isAuthenticated 
      && hasBusinessAccount 
      && !isSmall() 
      && !isXSmall() 
    " role="navigation" class="mat-component-background mat-elevation-z2">
    <app-menu></app-menu>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #mediumToolbar>
  <div *ngIf="isMedium(); else smallToolbar">
    <mat-toolbar class="mat-component-background mat-elevation-z2">
      <mat-toolbar-row>
        <div *ngIf="
            (isAuthenticated$ | async)?.isAuthenticated && hasBusinessAccount
          ">
          <span>
            <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()" color="primary">
              <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
            </button>
          </span>
        </div>
        <div *ngIf="
            !(isAuthenticated$ | async)?.isAuthenticated;
            else dashboardHomeButton
          ">
          <span>
            <button mat-button routerLink="/home" [ngClass]="{
                'banner-button-xlarge': isXLarge(),
                'banner-button-large': isLarge(),
                'banner-button-medium': isMedium(),
                'banner-button-small': isSmall(),
                'banner-button-xsmall': isXSmall()
              }">
              <img src="/assets/img/planetvg-partner-banner.png" alt="planetvg-banner" />
            </button>
          </span>
        </div>
        <div class="container-buttons-medium">
          <ul class="toolbar-row-buttons-medium">
            <li>
              <div *ngIf="
                  !(isAuthenticated$ | async)?.isAuthenticated ||
                  ((isAuthenticated$ | async)?.isAuthenticated &&
                    hasNoActiveSubscriptionPlanOrder)
                ">
                <span><a href="#" mat-button color="primary" routerLink="/about" class="topmenu-item">About</a></span>
              </div>
            </li>
            <li>
              <div *ngIf="
                  !(isAuthenticated$ | async)?.isAuthenticated ||
                  ((isAuthenticated$ | async)?.isAuthenticated &&
                    hasNoActiveSubscriptionPlanOrder)
                ">
                <span><a href="#" mat-button color="primary" routerLink="/how-it-works" class="topmenu-item">How it
                    works</a></span>
              </div>
            </li>
            <li>
              <div *ngIf="
                  !(isAuthenticated$ | async)?.isAuthenticated ||
                  ((isAuthenticated$ | async)?.isAuthenticated &&
                    hasNoActiveSubscriptionPlanOrder)
                ">
                <span><a href="#" mat-button color="primary" routerLink="/pricing"
                    class="topmenu-item">Pricing</a></span>
              </div>
            </li>
            <li>
              <div *ngIf="
                  !(isAuthenticated$ | async)?.isAuthenticated ||
                  ((isAuthenticated$ | async)?.isAuthenticated &&
                    hasNoActiveSubscriptionPlanOrder)
                ">
                <button mat-icon-button [matMenuTriggerFor]="mediumPublicMenu">
                  <mat-icon>more_vert</mat-icon>
                </button>
              </div>
            </li>
            <li>
              <div *ngIf="!(isAuthenticated$ | async)?.isAuthenticated">
                <button mat-raised-button color="primary" [routerLink]="['/register']">
                  <mat-icon>app_registration</mat-icon>
                  Sign Up
                </button>
              </div>
            </li>
            <li>
              <div *ngIf="(isAuthenticated$ | async)?.isAuthenticated; else noAuth">
                <button mat-mini-fab color="primary" [matMenuTriggerFor]="menu">
                  <mat-icon>person_outline</mat-icon>
                </button>
                <mat-menu #menu="" matMenu class="mat-component-background">
                  <a href="{{ accountPortalUrl }}" target="_blank" rel="noopener"
                    mat-menu-item><mat-icon>person</mat-icon>Profile<mat-icon>open_in_new</mat-icon></a>
                  <div *ngIf="
                      (isAuthenticated$ | async)?.isAuthenticated &&
                      hasBusinessAccount
                    ">
                    <button mat-menu-item [routerLink]="['/account']" routerLinkActive="list-item-active">
                      <mat-icon>manage_accounts</mat-icon>
                      <span>Account</span>
                    </button>
                    <button mat-menu-item [routerLink]="['/billing']" routerLinkActive="list-item-active">
                      <mat-icon>credit_card</mat-icon>
                      <span>Billing</span>
                    </button>
                  </div>
                  <button mat-menu-item [routerLink]="['/help-center']" routerLinkActive="list-item-active">
                    <mat-icon>help</mat-icon>
                    <span>Help Center</span>
                  </button>
                  <mat-divider></mat-divider>
                  <button mat-menu-item (click)="logout()">
                    <mat-icon><span class="material-icons">logout</span></mat-icon>
                    Sign Out
                  </button>
                </mat-menu>
              </div>
            </li>
          </ul>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
  </div>
</ng-template>

<ng-template #smallToolbar>
  <div *ngIf="isSmall(); else xSmallToolbar">
    <mat-toolbar class="mat-component-background mat-elevation-z2">
      <mat-toolbar-row>
        <div *ngIf="
            (isAuthenticated$ | async)?.isAuthenticated && hasBusinessAccount
          ">
          <span>
            <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()" color="primary">
              <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
            </button>
          </span>
        </div>
        <div *ngIf="
            !(isAuthenticated$ | async)?.isAuthenticated;
            else dashboardHomeButton
          ">
          <span>
            <button mat-button routerLink="/home" [ngClass]="{
                'banner-button-xlarge': isXLarge(),
                'banner-button-large': isLarge(),
                'banner-button-medium': isMedium(),
                'banner-button-small': isSmall(),
                'banner-button-xsmall': isXSmall()
              }">
              <img src="/assets/img/planetvg-partner-banner.png" alt="planetvg-banner" />
            </button>
          </span>
        </div>
        <div class="container-buttons-small">
          <ul class="toolbar-row-buttons-small">
            <li>
              <div *ngIf="!(isAuthenticated$ | async)?.isAuthenticated">
                <button mat-icon-button color="primary" [routerLink]="['/register']">
                  <mat-icon>app_registration</mat-icon>
                </button>
              </div>
            </li>
            <li>
              <div *ngIf="
                  ((isAuthenticated$ | async)?.isAuthenticated &&
                    hasNoActiveSubscriptionPlanOrder)
                ">
                <button mat-icon-button color="primary" [routerLink]="['/pricing']">
                  <mat-icon>storefront</mat-icon>
                </button>
              </div>
            </li>
            <li>
              <div *ngIf="
                  !(isAuthenticated$ | async)?.isAuthenticated ||
                  ((isAuthenticated$ | async)?.isAuthenticated &&
                    hasNoActiveSubscriptionPlanOrder)
                ">
                <button mat-icon-button [matMenuTriggerFor]="smallPublicMenu">
                  <mat-icon>more_vert</mat-icon>
                </button>
              </div>
            </li>
            <li>
              <div *ngIf="(isAuthenticated$ | async)?.isAuthenticated; else noAuth">
                <button mat-mini-fab color="primary" [matMenuTriggerFor]="menu">
                  <mat-icon>person_outline</mat-icon>
                </button>
                <mat-menu #menu="" matMenu class="mat-component-background">
                  <a href="{{ accountPortalUrl }}" target="_blank" rel="noopener" mat-menu-item><mat-icon><span
                        class="material-icons">person</span></mat-icon>Profile<mat-icon>open_in_new</mat-icon></a>
                  <div *ngIf="
                      (isAuthenticated$ | async)?.isAuthenticated &&
                      hasBusinessAccount
                    ">
                    <button mat-menu-item [routerLink]="['/account']" routerLinkActive="list-item-active">
                      <mat-icon>manage_accounts</mat-icon>
                      <span>Account</span>
                    </button>
                    <button mat-menu-item [routerLink]="['/billing']" routerLinkActive="list-item-active">
                      <mat-icon>credit_card</mat-icon>
                      <span>Billing</span>
                    </button>
                  </div>
                  <button mat-menu-item [routerLink]="['/help-center']" routerLinkActive="list-item-active">
                    <mat-icon>help</mat-icon>
                    <span>Help Center</span>
                  </button>
                  <mat-divider></mat-divider>
                  <button mat-menu-item (click)="logout()">
                    <mat-icon><span class="material-icons">logout</span></mat-icon>
                    Sign Out
                  </button>
                </mat-menu>
              </div>
            </li>
          </ul>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
  </div>
</ng-template>

<ng-template #xSmallToolbar>
  <div *ngIf="isXSmall(); else noToolbar">
    <mat-toolbar class="mat-component-background mat-elevation-z2">
      <mat-toolbar-row>
        <div *ngIf="
            (isAuthenticated$ | async)?.isAuthenticated && hasBusinessAccount
          ">
          <span>
            <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()" color="primary">
              <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
            </button>
          </span>
        </div>
        <div *ngIf="
            !(isAuthenticated$ | async)?.isAuthenticated;
            else dashboardHomeButton
          ">
          <span>
            <button mat-button routerLink="/home" [ngClass]="{
                'banner-button-xlarge': isXLarge(),
                'banner-button-large': isLarge(),
                'banner-button-medium': isMedium(),
                'banner-button-small': isSmall(),
                'banner-button-xsmall': isXSmall()
              }">
              <img src="/assets/img/planetvg-partner-banner.png" alt="planetvg-banner" />
            </button>
          </span>
        </div>
        <div class="container-buttons-xsmall">
          <ul class="toolbar-row-buttons-xsmall">
            <li>
              <div *ngIf="!(isAuthenticated$ | async)?.isAuthenticated">
                <button mat-icon-button color="primary" [routerLink]="['/register']">
                  <mat-icon>app_registration</mat-icon>
                </button>
              </div>
            </li>
            <li>
              <div *ngIf="
                  !(isAuthenticated$ | async)?.isAuthenticated ||
                  ((isAuthenticated$ | async)?.isAuthenticated &&
                    hasNoActiveSubscriptionPlanOrder)
                ">
                <button mat-icon-button [matMenuTriggerFor]="xSmallPublicMenu">
                  <mat-icon>more_vert</mat-icon>
                </button>
              </div>
            </li>
            <li>
              <div *ngIf="(isAuthenticated$ | async)?.isAuthenticated">
                <button mat-mini-fab color="primary" [matMenuTriggerFor]="menu">
                  <mat-icon>person_outline</mat-icon>
                </button>
                <mat-menu #menu="" matMenu class="mat-component-background">
                  <a href="{{ accountPortalUrl }}" target="_blank" rel="noopener" mat-menu-item><mat-icon><span
                        class="material-icons">person</span></mat-icon>Profile<mat-icon>open_in_new</mat-icon></a>
                  <div *ngIf="
                      (isAuthenticated$ | async)?.isAuthenticated &&
                      hasBusinessAccount
                    ">
                    <button mat-menu-item [routerLink]="['/account']" routerLinkActive="list-item-active">
                      <mat-icon>manage_accounts</mat-icon>
                      <span>Account</span>
                    </button>
                    <button mat-menu-item [routerLink]="['/billing']" routerLinkActive="list-item-active">
                      <mat-icon>credit_card</mat-icon>
                      <span>Billing</span>
                    </button>
                  </div>
                  <button mat-menu-item [routerLink]="['/help-center']" routerLinkActive="list-item-active">
                    <mat-icon>help</mat-icon>
                    <span>Help Center</span>
                  </button>
                  <mat-divider></mat-divider>
                  <button mat-menu-item (click)="logout()">
                    <mat-icon><span class="material-icons">logout</span></mat-icon>
                    Sign Out
                  </button>
                </mat-menu>
              </div>
            </li>
          </ul>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
  </div>
</ng-template>

<ng-template #noToolbar>
  <p>No Toolbar could be dislayed.</p>
</ng-template>

<ng-template #noAuth>
  <div *ngIf="!isSmall(); else smallEnterPortalButton">
    <button mat-raised-button (click)="login()">
      <mat-icon>login</mat-icon>Sign In
    </button>
  </div>
</ng-template>

<ng-template #smallEnterPortalButton>
  <button mat-icon-button (click)="login()">
    <mat-icon><span class="material-icons">login</span></mat-icon>
  </button>
</ng-template>

<ng-template #dashboardHomeButton>
  <div *ngIf="
      (isAuthenticated$ | async)?.isAuthenticated && hasBusinessAccount;
      else authenticatedHomeButton
    ">
    <span>
      <button mat-button routerLink="/dashboard" [ngClass]="{
          'banner-button-xlarge': isXLarge(),
          'banner-button-large': isLarge(),
          'banner-button-medium': isMedium(),
          'banner-button-small': isSmall(),
          'banner-button-xsmall': isXSmall()
        }">
        <img src="/assets/img/planetvg-partner-banner.png" alt="planetvg-banner" />
      </button>
    </span>
  </div>
</ng-template>

<ng-template #authenticatedHomeButton>
  <span>
    <button mat-button routerLink="/home" [ngClass]="{
        'banner-button-xlarge': isXLarge(),
        'banner-button-large': isLarge(),
        'banner-button-medium': isMedium(),
        'banner-button-small': isSmall(),
        'banner-button-xsmall': isXSmall()
      }">
      <img src="/assets/img/planetvg-partner-banner.png" alt="planetvg-banner" />
    </button>
  </span>
</ng-template>

<mat-menu #mediumPublicMenu="matMenu" class="mat-component-background">
  <ng-template matMenuContent>
    <button mat-menu-item [routerLink]="['/faq']" routerLinkActive="list-item-active">
      <span>FAQ</span>
    </button>
    <button mat-menu-item [routerLink]="['/help-center']" routerLinkActive="list-item-active">
      <span>Help Center</span>
    </button>
  </ng-template>
</mat-menu>

<mat-menu #smallPublicMenu="matMenu" class="mat-component-background">
  <ng-template matMenuContent>
    <button mat-menu-item [routerLink]="['/about']" routerLinkActive="list-item-active">
      <span>About</span>
    </button>
    <button mat-menu-item [routerLink]="['/how-it-works']" routerLinkActive="list-item-active">
      <span>How It Works</span>
    </button>
    <button mat-menu-item [routerLink]="['/pricing']" routerLinkActive="list-item-active">
      <span>Pricing</span>
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item [routerLink]="['/faq']" routerLinkActive="list-item-active">
      <span>FAQ</span>
    </button>
    <button mat-menu-item [routerLink]="['/help-center']" routerLinkActive="list-item-active">
      <span>Help Center</span>
    </button>
  </ng-template>
</mat-menu>

<mat-menu #xSmallPublicMenu="matMenu" class="mat-component-background">
  <ng-template matMenuContent>
    <button mat-menu-item [routerLink]="['/about']" routerLinkActive="list-item-active">
      <span>About</span>
    </button>
    <button mat-menu-item [routerLink]="['/how-it-works']" routerLinkActive="list-item-active">
      <span>How It Works</span>
    </button>
    <button mat-menu-item [routerLink]="['/pricing']" routerLinkActive="list-item-active">
      <span>Pricing</span>
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item [routerLink]="['/faq']" routerLinkActive="list-item-active">
      <span>FAQ</span>
    </button>
    <button mat-menu-item [routerLink]="['/help-center']" routerLinkActive="list-item-active">
      <span>Help Center</span>
    </button>
    <div *ngIf="!(isAuthenticated$ | async)?.isAuthenticated">
      <mat-divider></mat-divider>
      <button mat-menu-item (click)="login()">
        <mat-icon>login</mat-icon>
        <span>Sign In</span>
      </button>
    </div>
    <div *ngIf="(isAuthenticated$ | async)?.isAuthenticated">
      <mat-divider></mat-divider>
      <button mat-menu-item (click)="logout()">
        <mat-icon><span class="material-icons">logout</span></mat-icon>
        Sign Out
      </button>
    </div>
  </ng-template>
</mat-menu>

<mat-menu #resourcesMenu="matMenu" class="mat-component-background">
  <mat-nav-list>
    <a mat-list-item [routerLink]="['/faq']" routerLinkActive="list-item-active">FAQ</a>
    <a mat-list-item [routerLink]="['/help-center']" routerLinkActive="list-item-active">Help Center</a>
  </mat-nav-list>
</mat-menu>