<div class="container">
  <mat-card appearance="outlined" class="mat-component-background mat-elevation-z2">

    <div class="container-logo">
      <ng-container *ngIf="isImageSaved; else elseTemplate">
        <img [src]="(campaignLogoUrl$ | async)" class="logo" alt="stamp-card-logo" />
      </ng-container>
      <ng-template #elseTemplate>
        <img [src]="placeholderUrl" class="logo" alt="stamp-card-logo" />
      </ng-template>
    </div>

    <mat-card-actions class="container-actions">
      <div>
        <button mat-icon-button (click)="removeImage()" *ngIf="isImageSaved" matTooltip="Remove image">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
      <div>
        <button type="button" mat-icon-button (click)="fileInput.click()"
          matTooltip="Upload image"><mat-icon>upload</mat-icon></button>
        <input hidden (change)="fileChangeEvent($event)" #fileInput type="file" id="file" accept=".jpg,.jpeg,.png">
      </div>
    </mat-card-actions>
  </mat-card>
</div>