<div [ngClass]="{
  'container-content-xlarge': isXLarge(),
  'container-content-large': isLarge(),
  'container-content-medium': isMedium(),
  'container-content-small': isSmall(),
  'container-content-xsmall': isXSmall()
}">
  <div [ngClass]="{
  'container-promotion-xlarge': isXLarge(),
  'container-promotion-large': isLarge(),
  'container-promotion-medium': isMedium(),
  'container-promotion-small': isSmall(),
  'container-promotion-xsmall': isXSmall()
}">
    <div *ngIf="!editMode; else showEdit">
      <app-promotion [promotion]="(viewModel$ | async)?.promotion" (onDeletePressed)="onDelete($event)"
        (onActivatePressed)="onActivate($event)" (onDeactivatePressed)="onDeactivate($event)"
        (onEditPressed)="onEditPressed($event)"></app-promotion>
    </div>
  </div>
  <div class="container-promotion-logo">
    <app-promotion-logo [promotion]="(viewModel$ | async)?.promotion"></app-promotion-logo>
  </div>
</div>

<ng-template #showEdit>
  <app-promotion-edit [promotion]="(viewModel$ | async)?.promotion" (onCancelPressed)="onEditCancelPressed($event)"
    (onUpdateSuccess)="onUpdateSuccess($event)"></app-promotion-edit>
</ng-template>