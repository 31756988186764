<div class="container mat-component-background">
    <h1 mat-dialog-title>{{ data.title }}</h1>
    <div mat-dialog-content>
        <div class="container-content">
            <p>
                {{ data.message }}</p>
            <br />
            <p>
                <mat-form-field appearance="outline">
                    <mat-label>Link</mat-label>
                    <input matInput readonly value="{{ data.data }}" />
                </mat-form-field>
            </p>
        </div>
    </div>
    <div mat-dialog-actions class="container-actions">
        <button mat-raised-button color="secondary" [cdkCopyToClipboard]="data.data" (click)="onCopyClick()" matTooltip="Copy link to clipboard">
            <mat-icon>content_copy</mat-icon>
            Copy Link
        </button>
        <button mat-raised-button color="primary" (click)="onConfirmClick()" cdkFocusInitial>
            {{ data.confirm }}
        </button>
    </div>
</div>