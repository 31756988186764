import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { loadStripe } from '@stripe/stripe-js';
import { catchError, Observable, of, throwError } from 'rxjs';
import { PaymentInformationModel } from '../models/payment-information-model';
import { PaymentRequestModel } from '../models/payment-request-model';
import { environment } from './../../environments/environment';
import { UnknownErrorException } from '../core/exceptions/unknown-error-exception';
import { PaymentRequestActiveSubscriptionPlanOrderException } from '../core/exceptions/payment-request-active-subscription-plan-order-exception';
import { ValidationException } from '../core/exceptions/validation-exception';
import { CreateCheckoutException } from '../core/exceptions/create-checkout-exception';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {
  stripePromise = loadStripe(environment.stripe_apiKey);

  constructor(private http: HttpClient) {

  }

  checkoutShoppingCart(paymentRequest: PaymentRequestModel) : Observable<PaymentInformationModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        ContentType: 'application/json',
      }),
    };

    const url = environment.apiUrl + '/api/partner/payments';
    const paymentInformation$ = this.http.post<PaymentInformationModel>(url, paymentRequest, httpOptions);

    return paymentInformation$
      .pipe(
        catchError((response: HttpErrorResponse) => {
          if (response.status == 400) {
            if (response.error.violations) {
              const validationException = new ValidationException(response.error.violations);
              return throwError(() => validationException);
            }
          }
          else if (response.status == 409) {
            if (response.error.title == 'Can\'t create payment request. An active subscription plan order is already present.') {
              return throwError(() => new PaymentRequestActiveSubscriptionPlanOrderException());
            }
            return throwError(() => new CreateCheckoutException());
          }
          return throwError(() => new UnknownErrorException());
        })
      );
  }

  redeemCode() {
    // Check if we can redeem a promo code
  }

  /**
       * Handle Http operation that failed.
       * Let the app continue.
       * @param operation - name of the operation that failed
       * @param result - optional value to return as the observable result
       */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }
}
