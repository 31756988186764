<div class="container">
  <div *ngIf="showPricingBanner" class="container-column">
    <app-buy-subscription-plan-banner></app-buy-subscription-plan-banner>
  </div>
  <div *ngIf="unpaidInvoice$ | async">
    <app-unpaid-invoice-banner [unpaidInvoice]="unpaidInvoice$ | async"></app-unpaid-invoice-banner>
  </div>
  <div [ngClass]="{
    'container-content-xlarge': isXLarge(),
    'container-content-large': isLarge(),
    'container-content-medium': isMedium(),
    'container-content-small': isSmall(),
    'container-content-xsmall': isXSmall()
  }">
    <div [ngClass]="{
    'container-company-xlarge': isXLarge(),
    'container-company-large': isLarge(),
    'container-company-medium': isMedium(),
    'container-company-small': isSmall(),
    'container-company-xsmall': isXSmall()
  }">
      <div *ngIf="readMode; else editMode">
        <app-company [businessAccount]="businessAccount$ | async" (onEditPressed)="onEdit($event)"></app-company>
      </div>
    </div>
    <div class="container-company-logo">
      <app-company-logo [company]="(businessAccount$ | async)?.company"></app-company-logo>
    </div>
  </div>
</div>

<ng-template #editMode>
  <app-company-edit [minNameLength]="minNameLength" [maxNameLength]="maxNameLength"
    [minDescriptionLength]="minDescriptionLength" [maxDescriptionLength]="maxDescriptionLength"
    [minEmailLength]="minEmailLength" [maxEmailLength]="maxEmailLength" [minUrlLength]="minUrlLength"
    [maxUrlLength]="maxUrlLength" [businessAccount]="businessAccount$ | async"
    [companyDetailsFormGroup]="companyDetailsFormGroup" (onCancelPressed)="onCancel($event)"
    (onSavePressed)="onSave($event)"></app-company-edit>
</ng-template>