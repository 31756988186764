<div [ngClass]="{
    'container-xlarge': isXLarge(),
    'container-large': isLarge(),
    'container-medium': isMedium(),
    'container-small': isSmall(),
    'container-xsmall': isXSmall()
  }">
    <mat-card appearance="outlined" class="mat-component-background mat-elevation-z2">
        <mat-card-header>
            <div mat-card-avatar class="container-company-logo"
                [ngStyle]="{'background-image': 'url(' + testimonial.companyLogoUrl + ')'}">
            </div>
            <mat-card-subtitle>
                {{ testimonial.companyName }}
            </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content class="container-card-content">
            <p><em>
                {{ testimonial.description }}
            </em></p>
        </mat-card-content>
    </mat-card>
</div>