<div [ngClass]="{
  'container-xlarge': isXLarge(),
  'container-large': isLarge(),
  'container-medium': isMedium(),
  'container-small': isSmall(),
  'container-xsmall': isXSmall()
}">
  <mat-card appearance="outlined" class="mat-component-background mat-elevation-z2">
    <mat-card-header>
      <mat-card-title> Store Location Details </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div [ngClass]="{
        'container-content-xlarge': isXLarge(),
        'container-content-large': isLarge(),
        'container-content-medium': isMedium(),
        'container-content-small': isSmall(),
        'container-content-xsmall': isXSmall()
      }">
        <div class="container-column">
          <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput readonly value="{{ storeLocation?.name }}" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Description</mat-label>
            <input matInput readonly value="" />
            <textarea matInput placeholder="Ex. My store location description" cdkAutosizeMinRows="1"
              cdkAutosizeMaxRows="5" style="height: 5em;">{{ storeLocation?.description }}</textarea>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Type</mat-label>
            <input matInput readonly value="{{ storeLocation?.toStoreLocationTypeString() }}" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Category</mat-label>
            <input matInput readonly value="{{ storeLocation?.toStoreLocationCategoryString() }}" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Phone Number</mat-label>
            <input matInput readonly value="{{ storeLocation?.phoneNumber }}" />
          </mat-form-field>

        </div>
        <div class="container-column">
          <mat-form-field appearance="outline">
            <mat-label>Address Line 1</mat-label>
            <input matInput readonly value="{{ storeLocation?.addressLine1 }}" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Address Line 2</mat-label>
            <input matInput readonly value="{{ storeLocation?.addressLine2 }}" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Postal Code</mat-label>
            <input matInput readonly value="{{ storeLocation?.postalCode }}" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>City</mat-label>
            <input matInput readonly value="{{ storeLocation?.city }}" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Country</mat-label>
            <input matInput readonly value="{{ storeLocation?.toCountryString() }}" />
          </mat-form-field>
        </div>
        <div class="container-column-narrow">
          <mat-form-field appearance="outline">
            <mat-label>Status</mat-label>
            <input matInput readonly value="{{ storeLocation?.storeLocationStatus }}" />
          </mat-form-field>
          <app-store-location-options class="container-column"
            [storeLocationOptions]="storeLocation?.storeLocationOptions"></app-store-location-options>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions class="container-actions">
      <div *ngIf="canShowActions(); else showActivateActions">
        <button mat-icon-button (click)="edit()" matTooltip="Edit">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button (click)="delete()" matTooltip="Archive">
          <mat-icon>archive</mat-icon>
        </button>
      </div>
    </mat-card-actions>
  </mat-card>
</div>

<ng-template #showActivateActions>
  <div class="container-actions">
    <button mat-icon-button (click)="activate()" matTooltip="Activate">
      <mat-icon>play_circle</mat-icon>
    </button>
  </div>
</ng-template>